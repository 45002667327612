import React, { useState } from "react";
import axios from "axios";
import "./PopularCards.css";
import ListingComponent from "../../../ListingComponent/ListingComponent";
axios.defaults.withCredentials = true

function PopularCards() {
  const [show, setshow] = useState(false);
  const hasWindow = typeof window !== 'undefined';
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width
    };
  }

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  const posNeg = (value) => {
    if (value >= 0) {
      return <p className="pc__data__pch">+{value}%</p>;
    } else {
      return <p className="pc__data__pch__n">-{value}%</p>;
    }
  };

  const [popCards, setPopCards] = useState([]);
  React.useEffect(() => {
    axios
      .get(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/market/getms3"
      )
      .then((res) => {
        if (res.data.error) {
          alert(res.data.message);
        } else {
          setPopCards(res.data.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="popular__cards">
      <ListingComponent show={show} setShow={(b) => { setshow(b) }}
        data={popCards}
        order={[{ name: "name", displayName: "Card Name" }, { name: "set_name", displayName: "Set Name" }, { name: "volume", displayName: "90 Days Sales Volume" }, { name: "psa_10", displayName: "PSA 10 Price", type: "currency" }, { name: "psa_9", displayName: "PSA 9 Price", type: "currency" }, { name: "psa_8", displayName: "PSA 8 Price", type: "currency" }, { name: "year", displayName: "Release Year" }, { name: "language", displayName: "Language" }]}
      />
      <div className="pc__box">
        <div className="pc__heading__area">
          <div className="pc__heading">Popular Cards</div>
          <span className="pc__description">
            <p>Find out the most liquidate/popular cards up to date.</p>
            <p>(Basic Criteria: 90 Days Sales Volume {">"} 50)</p>
          </span>
        </div>
        <div className="pc__table__box">
          <div className="pc__info__box">
            <div className="pc__info__box__header">
              <div className="pc__cn">Card Name</div>
              <div className="pc__sn">Set Name</div>
              <div className="pc__dsv">90 Days Sales Volume</div>
              <div className="pc__10p">PSA 10 Price</div>
              <div className="pc__9p">PSA 9 Price</div>
              <div className="pc__8p">PSA 8 Price</div>
              <div className="pc__ry">Release Year</div>
              <div className="pc__l">Language</div>
            </div>
            <div className="pc__data">
              {windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width > 550 ? <>
                {popCards.slice(0, 8).map((item) => {
                  return (
                    <>
                      <div className="pc__data__row">
                        <p className="pc__data__cn">{item.name}</p>
                        <p className="pc__data__sn">{item.set_name}</p>
                        <p className="pc__data__dsv">
                          {item.volume}
                        </p>
                        <p className="pc__data__10p">
                          ${item.psa_10}
                        </p>
                        <p className="pc__data__9p">
                          ${item.psa_9}
                        </p>
                        <p className="pc__data__8p">
                          ${item.psa_8}
                        </p>
                        <p className="pc__data__ry">{item.year}</p>
                        <p className="pc__data__l">{item.language}</p>
                      </div>
                    </>
                  );
                })}</> : <>{popCards.slice(0, 5).map((item) => {
                  return (
                    <>
                      <div className="pc__data__row">
                        <p className="pc__data__cn">{item.name}</p>
                        <p className="pc__data__sn">{item.set_name}</p>
                        <p className="pc__data__dsv">
                          {item.volume}
                        </p>
                        <p className="pc__data__10p">
                          ${item.psa_10}
                        </p>
                        <p className="pc__data__9p">
                          ${item.psa_9}
                        </p>
                        <p className="pc__data__8p">
                          ${item.psa_8}
                        </p>
                        <p className="pc__data__ry">{item.year}</p>
                        <p className="pc__data__l">{item.language}</p>
                      </div>
                    </>
                  );
                })}</>}
            </div>

          </div>
          <div className="pc__fl">
            <div className="pc__fl__link" onClick={() => { setshow(true) }}>Full List {">>"}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularCards;
