import React from 'react'
import './LoginModal.css'
import logo from "../../assets/images/tcglogo(1).png";
import axios from 'axios';
import { useHistory } from 'react-router';
import validator from 'validator';
axios.defaults.withCredentials = true

function LoginModal({ openLoginModal, setOpenLoginModal, setOpenSignUpModal, setOpenForgotModal, setUserSession }) {

    const history = useHistory();
    const initialValues = { email: '', password: '' };
    const [formValues, setFormValues] = React.useState(initialValues);
    const [passwordType, setPasswordType] = React.useState('password');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [response, setResponse] = React.useState('');
    const [successfulLogin, setSuccessfulLogin] = React.useState(false);

    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setIsSubmit(true);

        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }

        const email = e.target[0].value;
        const password = e.target[1].value;

        if (email === '' || password === '') {
            setErrorMessage('All fields are required');
        } else if (isValidEmail(email) === false) {
            setErrorMessage('Please Enter Valid Email');
        } else {
            setErrorMessage('');
            const user = {
                email,
                password
            }

            axios
                .post(
                    "" + process.env.REACT_APP_BACKEND_URL + "api/user/login",
                    {
                        email: user.email,
                        password: user.password,
                    }
                )
                .then((res) => {
                    if (res.data.error) {
                        // alert(res.data.message);
                        setErrorMessage(res.data.message);
                        console.log(res)
                    } else {
                        setResponse(res.data.message)
                        console.log(res);
                        setIsSubmit(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            console.log(user);
        }
    }


    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (!errorMessage && isSubmit && response) {
                // alert(response);
                setSuccessfulLogin(true);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [errorMessage, isSubmit, response]);

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (successfulLogin) {
                // alert(response);
                setOpenLoginModal(false);
                setUserSession(true);
                history.push("/")
            }
        }, 2000);
        return () => clearTimeout(timeOutId);
    }, [successfulLogin, setOpenLoginModal]);


    if (!openLoginModal) return null;
    return (
        <div className="login__modal" onClick={() => setOpenLoginModal(false)}>
            <div class="login__screen" onClick={(e) => e.stopPropagation()}>
                {successfulLogin ?
                    <div class="login__screen__content__success">
                        <div class="login__close__button">
                            <i className="fas fa-times" onClick={() => setOpenLoginModal(false)}></i>
                        </div>
                        <div className='successful__login'>
                            <i className='successful__login__icon fas fa-check-circle'></i>
                            <div className='successful__login__text'>Welcome</div>
                            <div className='successful__login__text'>User Logged In Successfully</div>
                        </div>
                    </div>
                    :
                    <div class="login__screen__content">
                        <div class="login__close__button">
                            <i className="fas fa-times" onClick={() => setOpenLoginModal(false)}></i>
                        </div>
                        <div className="login__img">
                            <img src={logo} alt="" />
                        </div>
                        <div className="welcome__text">Welcome Back!</div>
                        <form class="login" onSubmit={handleSubmit}>
                            <div class="login__field">
                                <i class="login__icon fas fa-envelope"></i>
                                <input
                                    type="text"
                                    class="login__input"
                                    placeholder="Email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                />
                            </div>
                            <div class="login__field">
                                <i class="login__icon fas fa-lock"></i>
                                <input
                                    type={passwordType}
                                    class="login__input"
                                    placeholder="Password"
                                    name="password"
                                    value={formValues.password}
                                    onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                                />
                                {passwordType === 'password' ? <i class="login__icon__i fas fa-eye-slash" onClick={() => togglePasswordType()} /> : <i class="login__icon__i fas fa-eye" onClick={() => togglePasswordType()} />}
                            </div>
                            {errorMessage ? (
                                <div class="error__message">
                                    <i class="error__icon fas fa-exclamation-circle"></i>
                                    <span>{errorMessage}</span>
                                </div>
                            ) : null
                            }
                            <button class="button login__submit">
                                <span class="button__text">Log In Now</span>
                                <i class="login__button__icon fas fa-chevron-right"></i>
                            </button>
                            <div class="login__forgot">
                                <div class="login__forgot__link" onClick={() => { setOpenLoginModal(false); setOpenForgotModal(true) }}>Forgot Password?</div>
                            </div>
                            <div class="login__signup">
                                <span class="login__signup__text">Don't have an account?</span>
                                <div class="login__signup__link" onClick={() => { setOpenLoginModal(false); setOpenSignUpModal(true) }}>Sign Up</div>
                            </div>
                        </form>
                    </div>
                }
                <div class="login__screen__background">
                    <span class="login__screen__background__shape screen__background__shape4"></span>
                    <span class="login__screen__background__shape screen__background__shape3"></span>
                    <span class="login__screen__background__shape screen__background__shape2"></span>
                    <span class="login__screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
        </div>
    )

}

export default LoginModal