import React from 'react'
import './ResetModal.css'
import logo from "../../assets/images/tcglogo(1).png";
import axios from 'axios';
import { useHistory } from 'react-router';
import validator from 'validator';
axios.defaults.withCredentials = true

function ResetModal({ openResetModal, setOpenResetModal }) {

    const history = useHistory();
    const initialValues = { oldPassword: '', newPassword: '', confirmPassword: '' };
    const [formValues, setFormValues] = React.useState(initialValues);
    const [oldPasswordType, setOldPasswordType] = React.useState('password');
    const [passwordType, setPasswordType] = React.useState('password');
    const [password2Type, setPassword2Type] = React.useState('password');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [response, setResponse] = React.useState('');
    const [successful, setSuccessful] = React.useState(false);

    const toggleOldPasswordType = () => {
        if (oldPasswordType === 'password') {
            setOldPasswordType('text');
        } else {
            setOldPasswordType('password');
        }
    }
    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }
    const togglePassword2Type = () => {
        if (password2Type === 'password') {
            setPassword2Type('text');
        } else {
            setPassword2Type('password');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setIsSubmit(true);

        const oldPassword = e.target[0].value;
        const newPassword = e.target[1].value;
        const confirmPassword = e.target[2].value;

        if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
            setErrorMessage('All fields are required');
        } else if (newPassword.length < 6) {
            setErrorMessage('Password must be at least 6 characters');
        } else if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match');
        } else {
            setErrorMessage('');
            const user = {
                oldPassword,
                newPassword,
                confirmPassword
            }

            axios
                .post(
                    "" + process.env.REACT_APP_BACKEND_URL + "api/user/reset-password",
                    {
                        oldPassword: user.oldPassword,
                        newPass1: user.newPassword,
                        newPass2: user.confirmPassword,
                    }
                )
                .then((res) => {
                    if (res.data.error) {
                        // alert(res.data.message);
                        setErrorMessage(res.data.message);
                        console.log(res)
                    } else {
                        setResponse(res.data.message)
                        console.log(res);
                        setIsSubmit(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            console.log(user);
        }
    }


    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (!errorMessage && isSubmit && response) {
                // alert(response);
                setSuccessful(true);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [errorMessage, isSubmit, response]);

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (successful) {
                // alert(response);
                setOpenResetModal(false);
                history.push("/")
            }
        }, 2000);
        return () => clearTimeout(timeOutId);
    }, [successful, setOpenResetModal]);


    if (!openResetModal) return null;
    return (
        <div className="reset__modal" onClick={() => setOpenResetModal(false)}>
            <div class="reset__screen" onClick={(e) => e.stopPropagation()}>
                {successful ?
                    <div class="reset__screen__content__success">
                        <div className='successful__reset'>
                            <i className='successful__reset__icon fas fa-check-circle'></i>
                            <div className='successful__reset__text'>Password Changed Successfully</div>
                        </div>
                    </div>
                    :
                    <div class="reset__screen__content">
                        <div class="login__close__button">
                            <i className="fas fa-times" onClick={() => setOpenResetModal(false)}></i>
                        </div>
                        <div className="reset__text">Change Password</div>
                        <form class="reset" onSubmit={handleSubmit}>
                        <div class="reset__field">
                                <i class="reset__icon fas fa-lock"></i>
                                <input
                                    type={oldPasswordType}
                                    class="reset__input"
                                    placeholder="Old Password"
                                    name='password'
                                    value={formValues.oldPassword}
                                    onChange={(e) => setFormValues({ ...formValues, oldPassword: e.target.value })}
                                />
                                {oldPasswordType === 'password' ? <i class="reset__icon__i fas fa-eye-slash" onClick={() => toggleOldPasswordType()} /> : <i class="reset__icon__i fas fa-eye" onClick={() => toggleOldPasswordType()} />}
                            </div>
                            <div class="reset__field">
                                <i class="reset__icon fas fa-lock"></i>
                                <input
                                    type={passwordType}
                                    class="reset__input"
                                    placeholder="Password"
                                    name='password'
                                    value={formValues.newPassword}
                                    onChange={(e) => setFormValues({ ...formValues, newPassword: e.target.value })}
                                />
                                {passwordType === 'password' ? <i class="reset__icon__i fas fa-eye-slash" onClick={() => togglePasswordType()} /> : <i class="reset__icon__i fas fa-eye" onClick={() => togglePasswordType()} />}
                            </div>
                            <div class="reset__field">
                                <i class="reset__icon fas fa-lock"></i>
                                <input
                                    type={password2Type}
                                    class="reset__input"
                                    placeholder="Confirm Password"
                                    name='confirmPassword'
                                    value={formValues.confirmPassword}
                                    onChange={(e) => setFormValues({ ...formValues, confirmPassword: e.target.value })}
                                />
                                {password2Type === 'password' ? <i class="reset__icon__i fas fa-eye-slash" onClick={() => togglePassword2Type()} /> : <i class="reset__icon__i fas fa-eye" onClick={() => togglePassword2Type()} />}
                            </div>
                            {errorMessage ? (
                                <div class="error__message">
                                    <i class="error__icon fas fa-exclamation-circle"></i>
                                    <span>{errorMessage}</span>
                                </div>
                            ) : null
                            }
                            <button class="button reset__submit">
                                <span class="button__text">Change Password</span>
                                <i class="button__icon fas fa-chevron-right"></i>
                            </button>
                        </form>
                    </div>
                }
                <div class="reset__screen__background">
                    <span class="reset__screen__background__shape screen__background__shape4"></span>
                    <span class="reset__screen__background__shape screen__background__shape3"></span>
                    <span class="reset__screen__background__shape screen__background__shape2"></span>
                    <span class="reset__screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
        </div>
    )

}

export default ResetModal