import React from 'react'
import './ForgotModal.css'
import logo from "../../assets/images/tcglogo(1).png";
import axios from 'axios';
import { useHistory } from 'react-router';
axios.defaults.withCredentials = true

function ForgotModal({ openForgotModal, setOpenForgotModal, setOpenLoginModal }) {

    // const history = useHistory();
    const initialValues = { email: '' };
    const [formValues, setFormValues] = React.useState(initialValues);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [response, setResponse] = React.useState('');
    const [successfull, setSuccessfull] = React.useState(false);

    // const togglePasswordType = () => {
    //     if (passwordType === 'password') {
    //         setPasswordType('text');
    //     } else {
    //         setPasswordType('password');
    //     }
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setIsSubmit(true);

        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }

        const email = e.target[0].value;

        if (email === '') {
            setErrorMessage('All fields are required');
        } else if (isValidEmail(email) === false) {
            setErrorMessage('Please Enter Valid Email');
        } else {
            setErrorMessage('');
            const user = {
                email,
            }

            axios
                .post(
                    "" + process.env.REACT_APP_BACKEND_URL + "api/user/request-reset-password",
                    {
                        email: user.email,
                    }
                )
                .then((res) => {
                    if (res.data.error) {
                        // alert(res.data.message);
                        setErrorMessage(res.data.message);
                        console.log(res.data.message);
                    } else {
                        setResponse(res.data.message)
                        // console.log(res.data.message);
                        setIsSubmit(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            console.log(user);
        }
    }


    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (!errorMessage && isSubmit && response) {
                setSuccessfull(true);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [errorMessage, isSubmit, response]);

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (successfull) {
                setOpenForgotModal(false);
                // history.push("/")
            }
        }, 2000);
        return () => clearTimeout(timeOutId);
    }, [successfull, setOpenForgotModal]);


    if (!openForgotModal) return null;
    return (
        <div className="forgot__modal" onClick={() => setOpenForgotModal(false)}>
            <div class="forgot__screen" onClick={(e) => e.stopPropagation()}>
                {successfull ?
                    <div class="forgot__screen__content__success">
                        <div class="forgot__close__button">
                            <i className="fas fa-times" onClick={() => setOpenForgotModal(false)}></i>
                        </div>
                        <div className='successful__forgot'>
                            <i className='successful__forgot__icon fas fa-check-circle'></i>
                            <div className='successful__forgot__text'>Sent</div>
                            <div className='successful__forgot__text'>Password reset email has been sent.</div>
                        </div>
                    </div>
                    :
                    <div class="forgot__screen__content">
                        <div class="forgot__close__button">
                            <i className="fas fa-times" onClick={() => setOpenForgotModal(false)}></i>
                        </div>
                        <div className="forgot__img">
                            <img src={logo} alt="" />
                        </div>
                        <div className="forgot__text">Forgot Password?</div>
                        <div className="forgot__sub__text">No worries, we'll send you reset instructions on your email.</div>
                        <form class="forgot" onSubmit={handleSubmit}>
                            <div class="forgot__field">
                                <i class="forgot__icon fas fa-envelope"></i>
                                <input
                                    type="text"
                                    class="forgot__input"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                />
                            </div>
                            {errorMessage ? (
                                <div class="error__message">
                                    <i class="error__icon fas fa-exclamation-circle"></i>
                                    <span>{errorMessage}</span>
                                </div>
                            ) : null
                            }
                            <button class="button forgot__submit">
                                <span class="button__text">Reset Password</span>
                                <i class="forgot__button__icon fas fa-chevron-right"></i>
                            </button>
                            <div class="forgot__login">
                                <span><i class="forgot__login__icon fas fa-chevron-left"></i></span>
                                <div class="forgot__login__link" onClick={() => { setOpenForgotModal(false); setOpenLoginModal(true) }}>{`Back to Log In`}</div>
                            </div>
                        </form>
                    </div>
                }
                <div class="forgot__screen__background">
                    <span class="forgot__screen__background__shape screen__background__shape4"></span>
                    <span class="forgot__screen__background__shape screen__background__shape3"></span>
                    <span class="forgot__screen__background__shape screen__background__shape2"></span>
                    <span class="forgot__screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
        </div>
    )

}

export default ForgotModal