import React from 'react'
import './RealtimeAuction.css'
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb'
import image from '../../assets/images/auction__page__image.jpeg'
import PieChart from '../../components/RealtimeAuctionCharts/PieChart/PieChart'
import LineChart from '../../components/RealtimeAuctionCharts/LineChart/LineChart'
import BarChart from '../../components/RealtimeAuctionCharts/BarChart/BarChart'
import BarChart2 from '../../components/RealtimeAuctionCharts/BarChart2/BarChart2'
import BarChart3 from '../../components/RealtimeAuctionCharts/BarChart3/BarChart3'
// import data from '../../data/data.json';
import { formatDate } from '../../assets/canvasjs.min'
import { formatTimeStr } from 'antd/lib/statistic/utils'
import axios from 'axios'
import { useHistory } from 'react-router'
axios.defaults.withCredentials = true

function RealtimeAuction() {
    const history = useHistory();
    const [refresh, setRefresh] = React.useState(false);
    // const [data.part_5_grade_distribution, setBarChart2DataPoints] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [part1, setPart1] = React.useState([]);


    React.useEffect(() => {
        if (error) setError(false);
        setData(null);
        const getData = async () => {
            setData(null);
            await axios.get(
                "" +
                process.env.REACT_APP_BACKEND_URL +
                "api/auctionMonitor/getData",
            ).then((res) => {
                if (!res.data.error) {
                    console.log(res);
                    setData(res.data.data);
                }
                else {
                    setError(true)
                    console.log(res.data.message);
                }
            }
            ).catch((err) => {
                console.log(err);
                setError(true)
            });
        }
        getData();
        setInterval(() => {
            getData();
        }, 900000);

        return () => { };
    }, [refresh])

    React.useEffect(() => {
        if (data) {
            console.log(data.part_2_pricing_analysis.classification.Value_1499_2499);
        }// setPart1([{ action__listing__time: data.part_1_auction_listing[0].auction_listing_time },
        // { auction_listing_total: data.part_1_auction_listing[0].auction_listing_total }])
        // console.log(part1);

    }, [data]);

    // function BarChart2Data() {
    //     let d = [];
    //     if (data) {
    //         for (let i = 1; i <= 10; i = i + 0.5) {
    //             d.push(data.part_5_grade_distribution);
    //         }
    //         console.log(d);

    //         let i = 0.5;
    //         let dataPoints = [];
    //         dataPoints = d.map((item) => {
    //             i++;
    //             return {
    //                 x: i = i - 0.5,
    //                 y: item[0],
    //             }

    //         })
    //         console.log(dataPoints);
    //         setBarChart2DataPoints(dataPoints);
    //     }
    //     return;
    // }

    // React.useEffect(() => {
    //     BarChart2Data();
    // }, [data]);

    const getSum = (datapoints) => {
        let sum = 0;
        // console.log(datapoints);
        datapoints.forEach((item) => {
            sum += item.count;
        })
        return sum;
    }

    const getGrade = (datapoints, grade) => {
        let value = 0;
        datapoints.forEach((item) => {
            if (item.grade == grade) {
                // console.log(item.x, grade)
                // console.log(item.y)
                value = item.count;
            }
        })
        return value;
    }

    const getUnder = (datapoints) => {
        let sum = 0;
        // console.log(datapoints);
        datapoints.forEach((item) => {
            if (item.grade + 0 <= 7.5)
                sum += item.count;
        })
        return sum;
    }

    const getClassificationSum = () => {
        if (data) {
            let sum = 0;
            let classification = data.part_2_pricing_analysis.classification;
            for (let key in classification) {
                sum += classification[key];
            }
            console.log(sum);
            return sum;
        }
    }

    return (
        <div className='realtime__auction__page'>
            {data ?
                <div className='realtime__auction__container'>
                    <div className='realtime__auction__container__top'>
                        <div className='realtime__auction__container__top__content'>
                            
                            <div className='ra__content'>
                                <div className='ra__content__top'>
                                    <div className='ra__content__top__left'>
                                        {/* <div className='ra__content__top__image'>
                                            <img src={image} alt='2xm' />
                                        </div> */}
                                        <BreadCrumb />
                                        <div className='ra__content__top__header'>
                                            <div className='ra__content__top__heading'>Realtime Auction Monitor</div>
                                            <div className='ra__content__top__sub__heading'>(Pokemon TCG Market Auction Tracker)</div>
                                            <div className='ra__content__top__right__update__time'>
                                                Last Update: {formatDate(new Date(data.part_1_auction_listing.auction_listing_time), "YYYY-MMM-DD H:mm")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ra__content__top__right'>
                                        <div className='ra__content__top__right__update'>
                                            <div className='ra__content__top__right__update__icon' onClick={() => setRefresh(!refresh)}>
                                                <span><i className='fa fa-refresh'/></span> Refresh Page
                                            </div>
                                            
                                        </div>
                                        <div className='ra__content__top__right__update__info'>
                                            <div className='ra__content__top__right__update__info__row'>Updates every 20 min. Refresh the page to get the latest info.</div>
                                            <div className='ra__content__top__right__update__info__row_u'>For information and education purposes only.</div>
                                            <div className='ra__content__top__right__update__info__row_u'>All information used are public information.</div>
                                            <div className='ra__content__top__right__update__info__row_u'>This page does not contain any financial advice.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ra__content__container'>
                        <div className='ra__content'>
                            <div className='ra__content__top__charts'>
                                <div className='ra__content__top__details'>
                                    <div className='ra__content__top__details__header'>
                                        Total # Auction listings ending in 12h
                                    </div>
                                    <div className='ra__content__top__details__total'>
                                        {data.part_1_auction_listing.auction_listing_total}
                                    </div>
                                    <div className='ra__content__top__details__content'>
                                        <div className='ra__content__top__details__content__header'>
                                            Categorized by Grading Companies
                                        </div>
                                        <div className='ra__content__top__details__content__section'>
                                            <div className='ra__content__top__details__content__row'>
                                                <div className='ra__content__top__details__content__row__heading'>Total #:</div>
                                                <div className='ra__content__top__details__content__row__value'>
                                                    {data.part_1_auction_listing.auction_listing_total}
                                                    <span>{" (" + ((data.part_1_auction_listing.auction_listing_total) / (data.part_1_auction_listing.auction_listing_total)) * 100 + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__top__details__content__row'>
                                                <div className='ra__content__top__details__content__row__heading'>PSA #:</div>
                                                <div className='ra__content__top__details__content__row__value'>
                                                    {data.part_4_grading_company.PSA}
                                                    <span>{" (" + (((data.part_4_grading_company.PSA) / (data.part_1_auction_listing.auction_listing_total)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__top__details__content__row'>
                                                <div className='ra__content__top__details__content__row__heading'>BGS #:</div>
                                                <div className='ra__content__top__details__content__row__value'>
                                                    {data.part_4_grading_company.BGS}
                                                    <span>{" (" + (((data.part_4_grading_company.BGS) / (data.part_1_auction_listing.auction_listing_total)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__top__details__content__row'>
                                                <div className='ra__content__top__details__content__row__heading'>CGC #:</div>
                                                <div className='ra__content__top__details__content__row__value'>
                                                    {data.part_4_grading_company.CGC}
                                                    <span>{" (" + (((data.part_4_grading_company.CGC) / (data.part_1_auction_listing.auction_listing_total)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__top__details__content__row'>
                                                <div className='ra__content__top__details__content__row__heading'>Others #:</div>
                                                <div className='ra__content__top__details__content__row__value'>
                                                    {data.part_1_auction_listing.auction_listing_total - (data.part_4_grading_company.PSA + data.part_4_grading_company.BGS + data.part_4_grading_company.CGC)}
                                                    <span>{" (" + (((data.part_1_auction_listing.auction_listing_total - (data.part_4_grading_company.PSA + data.part_4_grading_company.BGS + data.part_4_grading_company.CGC)) / (data.part_1_auction_listing.auction_listing_total)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='ra__content__top__details__info'>
                                        This shows an approximate number, due to technical difficulties, it is hard to cover all listings, some will be missing.
                                    </div>
                                </div>
                                <div className='ra__content__top__chart'>
                                    <div className='ra__content__top__chart__header'>
                                        Auction listings ending in next 12h (Ending Time Distribution (EST))
                                    </div>
                                    <div className='ra__content__top__chart__chart'>
                                        <BarChart data={data.part_1_auction_listing.chart_data} />
                                    </div>
                                    <div className='ra__content__top__chart__info'>
                                        This shows in detail how many listings will end within the next 12h, aggregates the # of auctions with respect to their ending time.
                                    </div>
                                </div>
                            </div>
                            <div className='ra__content__analysis__left__current__auction'>
                                <div className='ra__content__analysis__left__current__auction__heading'>Current Auction Price Distribution</div>
                                <div className='ra__content__analysis__left__current__auction__content'>
                                    <div className='ra__content__analysis__left__current__auction__content__column'>
                                        <div className='ra__content__analysis__left__current__auction__content__column__header'>Low-end $0 - $200</div>
                                        <BarChart3 data={data.part_7_price_300} />
                                    </div>
                                    <div className='ra__content__analysis__left__current__auction__content__column'>
                                        <div className='ra__content__analysis__left__current__auction__content__column__header'>Mid-end $200 - $800</div>
                                        <BarChart3 data={data.part_7_price_800} />
                                    </div>
                                    <div className='ra__content__analysis__left__current__auction__content__column'>
                                        <div className='ra__content__analysis__left__current__auction__content__column__header'>High-end $800 - $5000</div>
                                        <BarChart3 data={data.part_7_price_5000} />
                                    </div>
                                </div>
                            </div>
                            <div className='ra__content__analysis__left__pricing__analysis__header'>
                                <div className='ra__content__analysis__left__pricing__analysis__heading'>Pricing Analysis</div>
                                <div className='ra__content__analysis__left__pricing__analysis__sub__heading'>{`( ${formatDate(new Date(data.part_1_auction_listing.auction_listing_time), "YYYY-MMM-DD H:mm")} )`}</div>
                            </div>
                            <div className='ra__content__analysis'>
                                <div className='ra__content__analysis__left'>
                                    <div className='ra__content__analysis__left__pricing__analysis'>

                                        <div className='ra__content__analysis__left__pricing__analysis__content'>
                                            <div className='ra__content__analysis__left__pricing__analysis__content__column'>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__heading'>Overvalued</div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__header'>
                                                    {`Current Auction Price Exceeded Fair Value(>+15%)`}
                                                </div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__values'>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__value'>{data.part_2_pricing_analysis.count_breakdown.greater_than_15}</div>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__percentage'>{(data.part_2_pricing_analysis.count_breakdown.greater_than_15 / (data.part_2_pricing_analysis.count_breakdown.greater_than_15 + data.part_2_pricing_analysis.count_breakdown.in_the_middle + data.part_2_pricing_analysis.count_breakdown.less_than_neg_15) * 100).toFixed(2) + "%"}</div>
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__left__pricing__analysis__content__column'>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__heading'>Fair Valued</div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__header'>
                                                    {`Auction Price Close to Fair value (+/-15% range)`}
                                                </div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__values'>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__value'>{data.part_2_pricing_analysis.count_breakdown.in_the_middle}</div>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__percentage'>{(data.part_2_pricing_analysis.count_breakdown.in_the_middle / (data.part_2_pricing_analysis.count_breakdown.greater_than_15 + data.part_2_pricing_analysis.count_breakdown.in_the_middle + data.part_2_pricing_analysis.count_breakdown.less_than_neg_15) * 100).toFixed(2) + "%"}</div>
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__left__pricing__analysis__content__column'>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__heading'>Undervalued</div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__header'>
                                                    {`Auction Price below Fair value (<-15% range)`}
                                                </div>
                                                <div className='ra__content__analysis__left__pricing__analysis__content__column__values'>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__value'>{data.part_2_pricing_analysis.count_breakdown.less_than_neg_15}</div>
                                                    <div className='ra__content__analysis__left__pricing__analysis__content__column__percentage'>{(data.part_2_pricing_analysis.count_breakdown.less_than_neg_15 / (data.part_2_pricing_analysis.count_breakdown.greater_than_15 + data.part_2_pricing_analysis.count_breakdown.in_the_middle + data.part_2_pricing_analysis.count_breakdown.less_than_neg_15) * 100).toFixed(2) + "%"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ra__content__analysis__left__pricing__analysis__info'>
                                            <div className='ra__content__analysis__left__pricing__analysis__info__row'>**For undervalue listings information, please visit the TCGFish undervalue page, thank you.</div>
                                            <div className='ra__content__analysis__left__pricing__analysis__info__row'>*Fair value is determined by TCGFish pricing algorithm.</div>
                                        </div>
                                        <div className='ra__content__analysis__left__pricing__analysis__button' onClick={() => history.push('/undervalue-bot')}>Jump to the undervalue bot page</div>
                                    </div>
                                    <div className='ra__content__analysis__left__prediction__accuracy'>
                                        <div className='ra__content__analysis__left__prediction__accuracy__heading'>
                                            TCGFish Prediction Accuracy (Past 24h)
                                        </div>
                                        <div className='ra__content__analysis__left__prediction__accuracy__content'>
                                            <div className='ra__content__analysis__left__prediction__accuracy__content__box'>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__heading'>
                                                    Total Amount of Listing's final sales Price Predicted
                                                </div>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__value'>
                                                    {data.part_3_prediction_accuracy.total_listing}
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__left__prediction__accuracy__content__box'>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__heading'>
                                                    Predicted Price within +/-15% of final sales price
                                                </div>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__value' style={{ color: '#FDFD3D' }}>
                                                    {data.part_3_prediction_accuracy.prediction_15}
                                                    <span>{"(" + ((data.part_3_prediction_accuracy.prediction_15 / data.part_3_prediction_accuracy.total_listing) * 100).toFixed(1) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__left__prediction__accuracy__content__box'>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__heading'>
                                                    Predicted Price within +/-20% of final sale price
                                                </div>
                                                <div className='ra__content__analysis__left__prediction__accuracy__content__box__value' style={{ color: '#8df13a' }}>
                                                    {data.part_3_prediction_accuracy.prediction_20}
                                                    <span>{"(" + ((data.part_3_prediction_accuracy.prediction_20 / data.part_3_prediction_accuracy.total_listing) * 100).toFixed(1) + "%)"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ra__content__analysis__left__market__movement'>
                                        <div className='ra__content__analysis__left__market__movement__heading'>
                                            Market Movement (Past 24h)
                                        </div>
                                        <div className='ra__content__analysis__left__market__movement__content'>
                                            <div className='ra__content__analysis__left__market__movement__content__info'>
                                                This is an indicator of the market movement, can also be used as a risk indicator.
                                            </div>
                                            <div className='ra__content__analysis__left__market__movement__content__box'>
                                                <div className='ra__content__analysis__left__market__movement__content__box__heading'>
                                                    Past 24h market movement
                                                </div>
                                                <div className='ra__content__analysis__left__market__movement__content__box__value' style={{ color: data.part_6_market_movement.past_24_movement > 0 ? "#8DF13A" : "#B2090C" }}>
                                                    {(data.part_6_market_movement.past_24_movement.toFixed(3) * 100).toFixed(2) + "%"}
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__left__market__movement__content__box'>
                                                <div className='ra__content__analysis__left__market__movement__content__box__heading'>
                                                    Volatility<br /> (Liquidity Risk)
                                                </div>
                                                <div className='ra__content__analysis__left__market__movement__content__box__value'>
                                                    {data.part_6_market_movement.risk}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='ra__content__analysis__right'>
                                    <div className='ra__content__analysis__right__pie__chart__section'>
                                        {/* <div className='ra__content__analysis__right__pie__chart__section__bottom'>
                                        <div className='ra__content__analysis__right__pie__chart__section__bottom__info'>Low-End Price Range: $1-$125</div>
                                        <div className='ra__content__analysis__right__pie__chart__section__bottom__info'>High-End Price Range: $325-$800</div>
                                        <div className='ra__content__analysis__right__pie__chart__section__bottom__info'>Mid-End Price Range: $125-$325</div>
                                        <div className='ra__content__analysis__right__pie__chart__section__bottom__info'>{`Ultra High-End Price Range: >$800`}</div>
                                    </div> */}
                                        <div className='ra__content__analysis__right__pie__chart__section__top'>
                                            <div className='ra__content__analysis__right__pie__chart__section__top__left'>
                                                <div className='ra__content__analysis__right__pie__chart__section__top__left__heading'>
                                                    Estimated Auction Ending Price Distribution
                                                </div>
                                                <div className='ra__content__analysis__right__pie__chart__section__top__left__content'>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $1-$99:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#5590F7' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_1_99 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $99-$199:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#f4c313' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_99_199 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $199-$499:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#EFEFEF' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_199_499 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $499-$1499:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#8df13a' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_499_1499 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $1499-$2499:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#ECF500' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_1499_2499 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $2499-$4999:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#E9068B' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_2499_4999 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                    <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row'>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__heading'>Value $5000+:</div>
                                                        <div className='ra__content__analysis__right__pie__chart__section__top__left__content__row__value' style={{ color: '#FFFFFF' }}>
                                                            {((data.part_2_pricing_analysis.classification.Value_5000 / getClassificationSum()) * 100).toFixed(2) + "%"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ra__content__analysis__right__pie__chart__section__top__right'>
                                                <PieChart data={data.part_2_pricing_analysis.classification} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ra__content__analysis__right__info__section'>
                                        <div className='ra__content__analysis__right__info__section__top'>
                                            <div className='ra__content__analysis__right__info__section__top__p'>
                                                *Auction prices can be very volatile, a 25% price movement is very often. A $600 card can sale
                                                for $800 on a good day, which is 30% difference. Very often on weekends.
                                            </div>
                                            <div className='ra__content__analysis__right__info__section__top__p'>
                                                We can use our Fair Value Estimation as the benchmark. The higher the market volatility,
                                                the lower the prediction accuracy. Normally 80% accuracy on weekdays and 70% accuracy on weekends.
                                            </div>
                                        </div>
                                        <div className='ra__content__analysis__right__info__section__bottom'>
                                            <div className='ra__content__analysis__right__info__section__bottom__p'>
                                                ***This beta feature shows how volatile the past 24h market is.
                                            </div>
                                            <div className='ra__content__analysis__right__info__section__bottom__p'>
                                                Weekend auctions tend to have higher than normal movement.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='ra__content__charts'>
                                {/* <div className='ra__content__line__charts'>
                            <div className='ra__content__line__chart'>
                                <div className='ra__content__line__chart__header'>Total Auction listings by Grading Company (#)</div>
                                <div className='ra__content__line__chart__chart'>
                                    <LineChart />
                                </div>
                            </div>
                            <div className='ra__content__line__chart'>
                                <div className='ra__content__line__chart__header'>Total Auction listings by Grading Company (#)</div>
                                <div className='ra__content__line__chart__chart'>
                                    <LineChart />
                                </div>
                            </div>
                            <div className='ra__content__line__chart__legend'>
                                <div className='ra__content__line__chart__legend__heading'>Latest Value</div>
                                <div className='ra__content__line__chart__legend__sub__heading'>Apr-25-2023 16:45pm</div>
                                <div className='ra__content__line__chart__legend__content'>
                                    <div className='ra__content__line__chart__legend__content__row' style={{ marginBottom: '10px' }}>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>Total #:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value'>1322</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row'>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>PSA #:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#f4c313' }}>652</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row'>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>BGS #:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#f4c313' }}>35</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row' style={{ marginBottom: '30px' }}>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>CGC #:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#f4c313' }}>235</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row'>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>PSA %:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#8df13a' }}>65%</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row'>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>BGS %:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#8df13a' }}>3%</div>
                                    </div>
                                    <div className='ra__content__line__chart__legend__content__row'>
                                        <div className='ra__content__line__chart__legend__content__row__heading'>CGC %:</div>
                                        <div className='ra__content__line__chart__legend__content__row__value' style={{ color: '#8df13a' }}>32%</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                <div className='ra__content__bar__chart'>
                                    <div className='ra__content__bar__chart__left'>
                                        <div className='ra__content__bar__chart__left__header'>Total Auction listings (Grade Distribution)</div>
                                        <div className='ra__content__bar__chart__left__chart'>
                                            <BarChart2 data={data.part_5_grade_distribution} />
                                        </div>
                                    </div>
                                    <div className='ra__content__bar__chart__right'>
                                        <div className='ra__content__bar__chart__right__header'>
                                            <div className='ra__content__bar__chart__right__heading'>Grade Distribution Details</div>
                                            <div className='ra__content__bar__chart__right__sub__heading'>{formatDate(data.part_1_auction_listing.auction_listing_time, "YYYY-MMM-DD H:mm")}</div>
                                        </div>
                                        <div className='ra__content__bar__chart__right__content'>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Total #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value'>
                                                    {getSum(data.part_5_grade_distribution)}
                                                    <span>{" (" + ((getSum(data.part_5_grade_distribution) / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade 10 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value' style={{ color: '#f4c313' }}>
                                                    {getGrade(data.part_5_grade_distribution, '10')}
                                                    <span>{" (" + ((getGrade(data.part_5_grade_distribution, '10') / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade 9.5 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value' style={{ color: '#8df13a' }}>
                                                    {getGrade(data.part_5_grade_distribution, '9.5')}
                                                    <span>{" (" + ((getGrade(data.part_5_grade_distribution, '9.5') / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade 9 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value'>
                                                    {getGrade(data.part_5_grade_distribution, '9')}
                                                    <span>{" (" + ((getGrade(data.part_5_grade_distribution, '9') / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade 8.5 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value'>
                                                    {getGrade(data.part_5_grade_distribution, '8.5')}
                                                    <span>{" (" + ((getGrade(data.part_5_grade_distribution, '8.5') / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade 8 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value'>
                                                    {getGrade(data.part_5_grade_distribution, '8')}
                                                    <span>{" (" + ((getGrade(data.part_5_grade_distribution, '8') / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                            <div className='ra__content__bar__chart__right__content__row'>
                                                <div className='ra__content__bar__chart__right__content__row__heading'>Grade Equal/Under 7.5 #:</div>
                                                <div className='ra__content__bar__chart__right__content__row__value'>
                                                    {getUnder(data.part_5_grade_distribution)}
                                                    <span>{" (" + ((getUnder(data.part_5_grade_distribution) / getSum(data.part_5_grade_distribution)) * 100).toFixed(2) + "%)"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='ra__preloader__whole'>
                    <div className="ra__preloader__container">
                        <div className="ra__preloader">
                            <div className="ra__preloader">
                                <div className="ra__preloader">
                                    <div className="ra__preloader">
                                        <div className="ra__preloader">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}

export default RealtimeAuction