import React from "react";
import axios from "axios";
import "./Product.css";
import empty from "../../assets/images/empty.webp";
import { Link, useLocation, useHistory } from "react-router-dom";
import Snapshot from "../../components/Snapshot/Snapshot";
import Statistics from "../../components/Statistics/Statistics";
import Chart from "../../components/Chart/Chart";
import Export from "../../components/Export/Export";
import Analysis from "../../components/Analysis/Analysis";
import Navbar from "../../components/NavbarRest/NavbarRest";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCopyToClipboard } from "usehooks-ts";
import { Alert } from "@mui/material";
axios.defaults.withCredentials = true

export default function Product() {
  const history = useHistory();
  const search = useLocation().search;
  const [error, setError] = React.useState(false);
  const [id, setId] = React.useState(new URLSearchParams(search).get("id"));
  const [cardNumber, setCardNumber] = React.useState(
    new URLSearchParams(search).get("cardNumber")
  );
  const [versionNumber, setVersionNumber] = React.useState(0);
  const [card, setcard] = React.useState(null);
  const [percentage, setpercentage] = React.useState(0);
  const [ratio, setratio] = React.useState(0);
  const [cardSales, setcardSales] = React.useState(null);
  const [latestSale, setLatestSale] = React.useState(null);
  const [lastSold, setLastSold] = React.useState(null);
  const [cardPopulation, setcardPopulation] = React.useState(undefined);
  const [allSales, setallSales] = React.useState([]);
  const [chartSectionToggle, setChartSectionToggle] = React.useState("sales");
  const [subCategory, setSubCategory] = React.useState();
  const [value, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = React.useState(false);

  const hasWindow = typeof window !== "undefined";
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width,
    };
  }

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  React.useEffect(() => {
    setId(new URLSearchParams(search).get("id"));
    setCardNumber(new URLSearchParams(search).get("cardNumber"));
  }, [window.location.pathname.split("/")]);

  React.useEffect(() => {
    if (!id && !cardNumber) {
      setError(true);
    }
  }, [id, cardNumber]);

  React.useEffect(() => {
    axios
      .post(
        "" + process.env.REACT_APP_BACKEND_URL + "api/cards/getOneCardByID",
        {
          cardid: id,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setcard(res.data.data);
          // setVersionNumber(0);
        } else {
          console.log(res.data.message);
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }, [id]);

  React.useEffect(() => {
    axios
      .post(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/sales/getAllSalesForOneCard",
        {
          card_id: cardNumber,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setcardSales(res.data.data);
          setLatestSale(res.data.data.lastmodified);
          setLastSold(res.data.latest);
          console.log(res.data);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });

    axios
      .post(
        "" + process.env.REACT_APP_BACKEND_URL + "api/population/getPopulation",
        {
          card_id: cardNumber,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setcardPopulation(res.data.count[0]);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, [cardNumber]);

  const calculateRatio = () => {
    let output = 0;
    let a = 0;
    let b = 0;
    let result = 0.0;
    let tempRatio = 0.0;
    if (cardPopulation) {
      b = cardPopulation.psa_10;
      a = cardPopulation.psa_9;
      if (a > 0 && b > 0) {
        result = a / b;
        tempRatio = result.toFixed(2) + " : 1";
        output = (b / (b + a)) * 100;
        output = output.toFixed(1);
      } else if (b === 0 && a > 0) {
        tempRatio = "NO PSA 10";
        output = 0.0;
      } else if (b > 0 && a === 0) {
        tempRatio = a + " : " + b;
        output = 100;
      }
      else {
        tempRatio = "NO PSA 10";
        output = 0.0;
      }
      setpercentage(output);
      setratio(tempRatio);
    } else {
      setpercentage(0);
      setratio(0);
    }
    calculateGrade();
  };

  const calculateGrade = () => {
    if (percentage < 15) return "Very Hard";
    else if (percentage >= 15 && percentage <= 30) return "Hard";
    else if (percentage >= 30 && percentage <= 50) return "Moderate";
    else if (percentage >= 50 && percentage <= 70) return "Easy";
    else if (percentage > 70) return "Very Easy";
  };

  function salesdata() {
    if (cardSales) {
      let tempSales = [];
      let records = [];
      records = cardSales.grading_company;
      if (records) {
        if (records.psa) {
          for (var i = 0; i < records.psa.length; i++) {
            for (var j = 0; j < records.psa[i].length; j++) {
              let temp = records.psa[i][j];
              temp.date = temp.date.slice(0, 10).split("-").reverse().join("-");
              tempSales.push(temp);
            }
          }
        }
        if (records.bgs) {
          for (var m = 0; m < records.bgs.length; m++) {
            for (var n = 0; n < records.bgs[m].length; n++) {
              records.bgs[m][n].date = records.bgs[m][n].date
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-");
              tempSales.push(records.bgs[m][n]);
            }
          }
        }
        if (records.cgc) {
          for (var r = 0; r < records.cgc.length; r++) {
            for (var s = 0; s < records.cgc[r].length; s++) {
              records.cgc[r][s].date = records.cgc[r][s].date
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-");
              tempSales.push(records.cgc[r][s]);
            }
          }
        }
      }
      setallSales(tempSales);
    }
  }

  function PanelToggle(value) {
    if (value === 'sales') setChartSectionToggle("sales");
    else if (value === 'export') setChartSectionToggle("export");
  }

  React.useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  React.useEffect(() => {
    calculateRatio();
  }, [cardPopulation]);

  React.useEffect(() => {
    if (card && card.sub_cat) {
      card.sub_cat.map((item, index) => {
        if (item.card_id === cardNumber) {
          setSubCategory(item.sub_category);
          setVersionNumber(index);
        }
      })
    }
  }, [cardSales, card, cardNumber, id, versionNumber]);

  React.useEffect(() => {
    salesdata();
  }, [cardSales]);


  const cardWrapper = document.querySelector('.big-img');

  if (error) {
    return (
      <>
        {/* <Navbar /> */}
        <div className="product__preloader__whole">
          <div className="product__error__message">
            ERROR Loading Page
            <br />
            <br />
            Please check the URL and reload again
          </div>
        </div>
      </>
    );
  } else if (card && cardSales && cardPopulation) {
    return (
      <>
        {/* <Navbar /> */}

        <div className="product">
          {isCopied ?
            <div className="alert">
              <Alert className="" severity="success">
                Copied to Clipboard!
              </Alert>
            </div>
            : null}
          <div className="product__breadcrumb">
            <BreadCrumb />
          </div>
          <div className="product__info">
            <div className="product__image">
              <div className="big-img">
                {cardSales && card ?
                  <><img
                    src={
                      card && card.sub_cat
                        ? card.sub_cat[versionNumber].image
                        : empty
                    }
                    alt="IMG"
                    onError={(e) =>
                    (e.target.src =
                      empty)
                    }
                  /></> : null}
                <div className="highlight"></div>
              </div>
            </div>
            <div className="product__title__container">
              {windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width > 550 ?
                <>

                  <div className="product__update__info">
                    <span>
                      <div className="product__last__sold">
                        {cardSales && lastSold
                          ? "Last Sold: " +
                          new Date(lastSold).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                          : null}
                      </div>
                      {cardSales && latestSale
                        ? "Latest Update: " +
                        new Date(latestSale).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        : null}
                    </span>
                  </div>
                </>
                : null
              }
              <div className="product__title">
                <div className="copy__link" onClick={() => {
                  copy(window.location.href);
                  setIsCopied(true);

                }}>
                  <i className="copy__link__lk fa fa-share-square"></i>
                </div>
                <h5>{card && card.name ? card.name : null}</h5>
                <div className="product__set__name">
                  {card && card.set_name ? (
                    <div>
                      {card.releaseYear} {card.set_name}{" "}
                      {card.card_number && card.card_number !== "Missing" ? (
                        <>#{card.card_number}</>
                      ) : null}{" "}
                      {card.language ? <span>({card.language})</span> : null}
                    </div>
                  ) : null}
                </div>
              </div>
              {windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width < 550 ?
                <>
                  <div className="product__update__info">
                    <div className="product__last__sold">
                      {cardSales && lastSold
                        ? "Last Sold: " +
                        new Date(lastSold).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        : null}
                    </div>
                    {cardSales && latestSale
                      ? "Latest Update: " +
                      new Date(latestSale).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      : null}
                  </div>
                </>
                : null
              }
              <div className="version__population__section">
                {cardPopulation ? (
                  <div className="version__container">
                    <h4>Selected Version:</h4>
                    <div className="versions">
                      {card && card.sub_cat
                        ? card.sub_cat.map((item) => {
                          if (item.card_id === cardNumber) {
                            return (
                              <Link
                                className="version__selected"
                              >
                                {item.sub_category}
                              </Link>
                            );
                          } else {
                            return (
                              <Link
                                className="version"
                                onClick={() => {
                                  if (cardPopulation && cardSales) {
                                    history.push(`/product?id=${card._id}&cardNumber=${item.card_id}`)
                                    setCardNumber(item.card_id);
                                    setpercentage(null);
                                    setcardSales(null);
                                    setcardPopulation(null);
                                    setVersionNumber(card.sub_cat.indexOf(item));
                                  }
                                }}
                              >
                                {item.sub_category}
                              </Link>
                            );
                          }
                        })
                        : null}
                    </div>
                  </div>)
                  : null}
                {windowDimensions &&
                  windowDimensions.width &&
                  windowDimensions.width > 550 ? (
                  <>
                    {cardPopulation ? (
                      <div className="population__container">
                        <div className="population__ratio__grade">
                          <div className="population__heading__span">
                            <div>
                              <p className="psa__heading__total">
                                PSA TOTAL POPULATION
                              </p>
                              <p className="psa__heading__total2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_total}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading__ratio">PSA 9/10 RATIO</p>
                              <p className="psa__heading__green">{ratio}</p>
                            </div>
                            <div>
                              <p className="psa__heading__chance">PSA 10 CHANCE</p>
                              <p className="psa__heading__green">{percentage}%</p>
                            </div>
                            <div>
                              <p className="psa__heading__grade">GRADE DIFFICULTY</p>
                              <p className="psa__heading__green">
                                {cardPopulation === undefined ? null : calculateGrade()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="population__section">
                          <div className="population__heading">
                            <div>
                              <p className="psa__heading">PSA 10</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_10}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 9</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_9}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 8</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_8}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 7</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_7}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 6</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_6}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 5</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_5}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 4</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_4}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 3</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_3}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 2</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_2}
                              </p>
                            </div>
                            <div>
                              <p className="psa__heading">PSA 1</p>
                              <p className="psa__heading2">
                                {cardPopulation === undefined
                                  ? null
                                  : cardPopulation.psa_1}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="dyk__section">
                          <div className="dyk__heading">
                            Did you know?
                          </div>
                          <div className="dyk__text">
                            There are more than 30,000 Pokémon cards on this website, both English and Japanese Cards. Feel free to check them out!
                          </div>
                        </div>
                      </div>
                    ) : cardPopulation ? (
                      <div className="product__error__message2">
                        No Population Data Available
                      </div>
                    ) : (
                      <div className="product__error__message">
                        Fetching Population Data...
                      </div>
                    )}
                  </>
                ) : null
                }
              </div>
            </div>
          </div>
          {windowDimensions &&
            windowDimensions.width &&
            windowDimensions.width < 550 ? (
            <>
              {cardPopulation ? (
                <div className="population__container">
                  <div className="population__ratio__grade">
                    <div className="population__heading__span">
                      <div>
                        <p className="psa__heading__total">
                          PSA TOTAL POPULATION
                        </p>
                        <p className="psa__heading__total2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_total}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading__ratio">PSA 9/10 RATIO</p>
                        <p className="psa__heading__green">{ratio}</p>
                      </div>
                      <div>
                        <p className="psa__heading__chance">PSA 10 CHANCE</p>
                        <p className="psa__heading__green">{percentage}%</p>
                      </div>
                      <div>
                        <p className="psa__heading__grade">GRADE DIFFICULTY</p>
                        <p className="psa__heading__green">
                          {cardPopulation === undefined ? null : calculateGrade()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="population__section">
                    <div className="population__heading">
                      <div>
                        <p className="psa__heading">PSA 10</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_10}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 9</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_9}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 8</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_8}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 7</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_7}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 6</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_6}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 5</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_5}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 4</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_4}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 3</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_3}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 2</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_2}
                        </p>
                      </div>
                      <div>
                        <p className="psa__heading">PSA 1</p>
                        <p className="psa__heading2">
                          {cardPopulation === undefined
                            ? null
                            : cardPopulation.psa_1}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="dyk__section">
                    <div className="dyk__heading">
                      Did you know?
                    </div>
                    <div className="dyk__text">
                      There are more than 28,000 Pokémon cards on this website, both English and Japanese Cards. Feel free to check them out!
                    </div>
                  </div>
                </div>
              ) : cardPopulation ? (
                <div className="product__error__message2">
                  No Population Data Available
                </div>
              ) : (
                <div className="product__error__message">
                  Fetching Population Data...
                </div>
              )}
            </>
          ) : null}
          {cardSales ? <Analysis card={card} id={cardNumber} allSales={allSales} /> : null}
          {cardSales ? (
            <>
              <div className="product__chart__section__header">
                <div className={(chartSectionToggle === 'sales') ? "product__chart__header__selected" : "product__chart__header"} onClick={() => PanelToggle('sales')}>
                  Sales History
                </div>
                <div className={(chartSectionToggle === 'export') ? "export__header__selected" : "export__header"} onClick={() => PanelToggle('export')}>
                  Data Export (Beta)
                </div>
              </div>
              {chartSectionToggle === 'sales' ? (
                <div className="chart__container">
                  <Chart allSales={allSales} />
                </div>
              ) : <div className="export__container">
                <Export card={card} subCategory={subCategory} allSales={allSales} />
              </div>}
            </>
          ) : null}


          {cardSales ? (
            <div className="statistics__header">
              (2019 - 2022) Overall Sales Statistics
            </div>
          ) : null}

          {cardSales ? <Statistics company="PSA" allSales={allSales} /> : null}
          {cardSales ? <Statistics company="CGC" allSales={allSales} /> : null}
          {cardSales ? <Statistics company="BGS" allSales={allSales} /> : null}

          <div style={{ display: "none" }}>
            <Statistics company="BGS" allSales={[]} />
          </div>
          {cardSales ? (
            <Snapshot allSales={allSales} />
          ) : (
            <div className="product__error__message">
              Fetching Sales Data...
            </div>
          )}

        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <Navbar /> */}
        <div className="product__preloader__whole">
          <div className="product__preloader__container">
            <div className="product__preloader">
              <div className="product__preloader">
                <div className="product__preloader">
                  <div className="product__preloader">
                    <div className="product__preloader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
