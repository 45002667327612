import React from 'react'
import './SignUpModal.css'
import logo from "../../assets/images/tcglogo(1).png";
import axios from 'axios';
import validator from 'validator';
axios.defaults.withCredentials = true

function SignUpModal({ openSignUpModal, setOpenSignUpModal, setOpenLoginModal }) {

    const initialValues = { userName: '', email: '', password: '', confirmPassword: '' };
    const [formValues, setFormValues] = React.useState(initialValues);
    const [passwordType, setPasswordType] = React.useState('password');
    const [confirmPasswordType, setConfirmPasswordType] = React.useState('password');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [response, setResponse] = React.useState('');
    const [successfulSignup, setSuccessfulSignup] = React.useState(false);

    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const toggleConfirmPasswordType = () => {
        if (confirmPasswordType === 'password') {
            setConfirmPasswordType('text');
        } else {
            setConfirmPasswordType('password');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setIsSubmit(true);

        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }

        const userName = e.target[0].value;
        const email = e.target[1].value;
        const password = e.target[2].value;
        const confirmPassword = e.target[3].value;


        if (userName === '' || email === '' || password === '' || confirmPassword === '') {
            setErrorMessage('All fields are required');
        } else if (isValidEmail(email) === false) {
            setErrorMessage('Email is invalid');
        } else if (password.length < 6) {
            setErrorMessage('Password must be at least 6 characters');
        } else if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
        } else {
            setErrorMessage('');
            const user = {
                userName,
                email,
                password,
                confirmPassword
            }

            axios
                .post(
                    "" + process.env.REACT_APP_BACKEND_URL + "api/user/register",
                    {
                        fullname: user.userName,
                        email: user.email,
                        password: user.password,
                        password2: user.confirmPassword,
                    }
                )
                .then((res) => {
                    if (res.data.error) {
                        // alert(res.data.message);
                        setErrorMessage(res.data.message);
                        console.log(res.data.message)
                    } else {
                        setResponse(res.data.message)
                        console.log(res.data.message);
                        setIsSubmit(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            console.log(user);
        }
    }

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (!errorMessage && isSubmit && response) {
                // alert(response);
                setSuccessfulSignup(true);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [errorMessage, isSubmit, response]);


    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (successfulSignup) {
                // alert(response);
                setOpenSignUpModal(false);
            }
        }, 5000);
        return () => clearTimeout(timeOutId);
    }, [successfulSignup]);




    return (
        <div className="signUp__modal" onClick={() => setOpenSignUpModal(false)}>
            <div class="screen" onClick={(e) => e.stopPropagation()}>
                {successfulSignup ?
                    <div className="successful__signup__screen__content">
                        <div class="close__button">
                            <i className="fas fa-times" onClick={() => setOpenSignUpModal(false)}></i>
                        </div>
                        <div className='successful__signup'>
                            <i className='successful__signup__icon fas fa-check-circle'></i>
                            <div className='successful__signup__text'>Registered</div>
                            <div className='successful__signup__text'>Please check your email for Verification.</div>
                        </div>
                    </div>
                    :
                    <div class="screen__content">
                        <div class="close__button">
                            <i className="fas fa-times" onClick={() => setOpenSignUpModal(false)}></i>
                        </div>
                        <div className="signUp__img">
                            <img src={logo} alt="" />
                        </div>
                        <div className="register__text">Create Account</div>
                        {/* <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
                        <form class="signUp" onSubmit={handleSubmit}>
                            <div class="signUp__field">
                                <i class="signUp__icon fas fa-user"></i>
                                <input
                                    type="text"
                                    class="signUp__input"
                                    placeholder="User Name"
                                    name='userName'
                                    value={formValues.userName}
                                    onChange={(e) => setFormValues({ ...formValues, userName: e.target.value })}
                                />
                            </div>
                            <div class="signUp__field">
                                <i class="signUp__icon fas fa-envelope"></i>
                                <input
                                    type="text"
                                    class="signUp__input"
                                    placeholder="Email"
                                    name='email'
                                    value={formValues.email}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                />
                            </div>
                            <div class="signUp__field">
                                <i class="signUp__icon fas fa-lock"></i>
                                <input
                                    type={passwordType}
                                    class="signUp__input"
                                    placeholder="Password"
                                    name='password'
                                    value={formValues.password}
                                    onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                                />
                                {passwordType === 'password' ? <i class="signUp__icon__i fas fa-eye-slash" onClick={() => togglePasswordType()} /> : <i class="signUp__icon__i fas fa-eye" onClick={() => togglePasswordType()} />}
                            </div>
                            <div class="signUp__field">
                                <i class="signUp__icon fas fa-lock"></i>
                                <input
                                    type={confirmPasswordType}
                                    class="signUp__input"
                                    placeholder="Confirm Password"
                                    name='confirmPassword'
                                    value={formValues.confirmPassword}
                                    onChange={(e) => setFormValues({ ...formValues, confirmPassword: e.target.value })}
                                />
                                {confirmPasswordType === 'password' ? <i class="signUp__icon__i fas fa-eye-slash" onClick={() => toggleConfirmPasswordType()} /> : <i class="signUp__icon__i fas fa-eye" onClick={() => toggleConfirmPasswordType()} />}
                            </div>
                            {errorMessage ? (
                                <div class="error__message">
                                    <i class="error__icon fas fa-exclamation-circle"></i>
                                    <span>{errorMessage}</span>
                                </div>
                            ) : null
                            }
                            <button class="button signUp__submit">
                                <span class="button__text">Sign Up Now</span>
                                <i class="button__icon fas fa-chevron-right"></i>
                            </button>
                            <div class="signup__login">
                                <span class="signup__login__text">Already a member?</span>
                                <div class="signup__login__link" onClick={() => { setOpenSignUpModal(false); setOpenLoginModal(true) }}>Log In</div>
                            </div>
                        </form>
                        {/* <div class="social-signUp">
                <h3>log in via</h3>
                <div class="social-icons">
                  <a href="#" class="social-signUp__icon fab fa-instagram"></a>
                  <a href="#" class="social-signUp__icon fab fa-facebook"></a>
                  <a href="#" class="social-signUp__icon fab fa-twitter"></a>
                </div>
              </div> */}
                    </div>
                }
                <div class="screen__background">
                    <span class="screen__background__shape screen__background__shape4"></span>
                    <span class="screen__background__shape screen__background__shape3"></span>
                    <span class="screen__background__shape screen__background__shape2"></span>
                    <span class="screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
        </div>
    )
}

export default SignUpModal