import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';

//Initialize GA4
ReactGA.initialize("G-FHJ86LB3W9");

//Root Renderer
ReactDOM.render(<App />, document.getElementById("root"));

//Analytics Function
const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}
reportWebVitals(SendAnalytics);
