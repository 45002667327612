import React, { useState } from "react";
import axios from "axios";
import "./RecordHigh.css";
import ListingComponent from "../../../ListingComponent/ListingComponent";
axios.defaults.withCredentials = true

function RecordHigh() {
  const [show, setshow] = useState(false);

  const [recordSalesHigh, setRecordSalesHigh] = useState([]);
  React.useEffect(() => {
    axios
      .get(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/market/getms6high"
      )
      .then((res) => {
        if (res.data.error) {
          alert(res.data.message);
        } else {
          let temp = res.data.count;
          for (var j = 0; j < temp.length; j++) {
            temp[j].date = temp[j].date
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-");
          }
          setRecordSalesHigh(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="record__high">
      <ListingComponent
        data={recordSalesHigh}
        show={show}
        setShow={(b) => { setshow(b) }}
        order={[{ name: "name", displayName: "Card Name" }, { name: "set_name", displayName: "Set Name" }, { name: "date", displayName: "Sold Date" }, { name: "grade", displayName: "Grade" }, { name: "sold", displayName: "Sold Price" }, { name: "Language", displayName: "Language" }]}
      />
      <div className="rehi__box">
        <div className="rehi__heading__area">
          <div className="rehi__heading">Record Sales / High</div>
          <span className="rehi__description">
            <p>Discover cards with rocket speed movement.</p>
            <p>Cards reaching 52W high (past 90 days) will be listed.</p>
          </span>
        </div>
        <div className="rehi__table__box">
          <div className="rehi__info__box">
            <div className="rehi__info__box__header">
              <p className="rehi__cn">Card Name</p>
              <p className="rehi__sn">Set Name</p>
              <p className="rehi__sd">Sold Date</p>
              <p className="rehi__g">Grade</p>
              <p className="rehi__sp">Sold Price</p>
              <p className="rehi__l">Language</p>
            </div>
            <div className="rehi__data">
              {recordSalesHigh.slice(0, 5).map((item) => {
                return (
                  <>
                    <div className="rehi__data__row">
                      <p className="rehi__data__cn">{item.name}</p>
                      <p className="rehi__data__sn">{item.set_name}</p>
                      <p className="rehi__data__sd">{item.date}</p>
                      <p className="rehi__data__g">{item.grade}</p>
                      <p className="rehi__data__sp">{item.sold}</p>
                      <p className="rehi__data__l">{item.Language}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="rehi__fl">
            <div className="rehi__fl__link" onClick={() => { setshow(true) }}>Full List {">>"}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecordHigh;
