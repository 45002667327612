import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import DoughnutChart from "../DoughnutChart/DoughnutChart";
import DoughnutChartMobile from "../DoughnutChartMobile/DoughnutChartMobile";
import { RadarChart } from "../RadarChart/RadarChart";
import "./Analysis.css";
import LoginModal from "../LoginModal/LoginModal";
axios.defaults.withCredentials = true

function Analysis({ card, id, allSales }) {
  const hasWindow = typeof window !== "undefined";
  const [rankData, setRankData] = React.useState(null);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [searchText, setSearchText] = React.useState("");
  const [versionName, setVersionName] = React.useState("");
  const [userSession, setUserSession] = React.useState(null);

  // console.log(card);
  
  const setVersion = () => {
    card.sub_cat.map((sub) => {
      if (sub.card_id === id) {
        setVersionName(sub.sub_category);
        // console.log(sub.sub_category);
      }
    })
  }
  
  React.useEffect(() => {
    if (!userSession) {
      axios.post(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/user/check-session"
      ).then((res) => {
        if (res.data.error) {
          setUserSession(false);
          console.log("User is not Logged In");
          console.log(res);
        } else {
          setUserSession(true);
          console.log("User is Logged In")
          console.log(res.data.message);
        }
      }
      ).catch((err) => {
        console.log(err);
      });
    }
  }, [userSession])

  React.useEffect(() => {
    // console.log(id);
    axios
      .post(
        "" + process.env.REACT_APP_BACKEND_URL + "api/ranks/getRankByID",
        {
          card_id: id,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setRankData(res.data.data);
          // console.log(res.data.data);
          // setVersionNumber(0);
          setVersion();
        } else {
          console.log(res.data.message);
          // setError(true);
        }
      })
      .catch((err) => {
        // setError(true);
        console.log(err);
      });
  }, [id]);

  React.useEffect(() => {
    setSearchText(card.name+" "+card.set_name+" "+card.releaseYear+" "+card.card_number+" "+card.language+" "+versionName);
  }, [versionName]);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width,
    };
  }
  const psaSales = allSales.filter((item) => item.company === "PSA");
  const bgsSales = allSales.filter((item) => item.company === "BGS");
  const cgcSales = allSales.filter((item) => item.company === "CGC");

  const psaVolume = psaSales.length;
  const bgsVolume = bgsSales.length;
  const cgcVolume = cgcSales.length;
  const dollarUSLocale = Intl.NumberFormat('en-US');

  const calculateVolume = (company) => {
    const sales = allSales.filter((item) => item.company === company);
    return sales.length;
  };

  const calculateAverage = (company) => {
    const total = allSales.length;
    const volume = calculateVolume(company);
    if (volume !== 0) {
      const avg = (volume / total) * 100;
      return (avg.toFixed(2));
    }
    else {
      return 0
    }
  };

  return (
    <div className="analysis">
      <div className="sales__volume__distribution__container">
        <h5 className="svd__heading">
          (2019 - 2022) Sales Volume Distribution by Grading Company
        </h5>
        <div className="svd__info__container">
          <div className="svd__legend">
            <div className="svd__legend__company">
              <div className="svd__legend__psa__button"></div>
              <div className="svd__legend__info">
                <div className="svd__legend__cards">
                  PSA Cards:{" "}
                  <span style={{ color: "#92D050" }}>
                    {calculateAverage("PSA") + "%"}
                  </span>
                </div>
                <div className="svd__legend__total">
                  Total: {calculateVolume("PSA")}
                </div>
              </div>
            </div>
            <div className="svd__legend__company">
              <div className="svd__legend__bgs__button"></div>
              <div className="svd__legend__info">
                <div className="svd__legend__cards">
                  BGS Cards:{" "}
                  <span style={{ color: "#B4C7E7" }}>
                    {calculateAverage("BGS") + "%"}
                  </span>
                </div>
                <div className="svd__legend__total">
                  Total: {calculateVolume("BGS")}
                </div>
              </div>
            </div>
            <div className="svd__legend__company">
              <div className="svd__legend__cgc__button"></div>
              <div className="svd__legend__info">
                <div className="svd__legend__cards">
                  CGC Cards:{" "}
                  <span style={{ color: "#BF9000" }}>
                    {calculateAverage("CGC") + "%"}
                  </span>
                </div>
                <div className="svd__legend__total">
                  Total: {calculateVolume("CGC")}
                </div>
              </div>
            </div>
          </div>
          {windowDimensions &&
            windowDimensions.width &&
            windowDimensions.width > 550 ?
            <>
              <div className="svd__pie__chart">
                <DoughnutChart
                  psa={calculateAverage("PSA")}
                  bgs={calculateAverage("BGS")}
                  cgc={calculateAverage("CGC")}
                />
              </div>
            </> : <>
              <div className="svd__pie__chart">
                <DoughnutChartMobile
                  psa={calculateAverage("PSA")}
                  bgs={calculateAverage("BGS")}
                  cgc={calculateAverage("CGC")}
                />
              </div>
            </>}
        </div>
      </div>
      <div className="radar__chart__section">
        <h5 className="svd__heading">
          Collectability Score
        </h5>
        <RadarChart id={id}/>
      </div>
      <div className="market__cap__section">
        <h5 className="market__cap__section__heading">
          Market Cap Info
        </h5>
        <div className="market__cap__container">
          <div className="market__cap__total">
            <div className="market__cap__heading">Total PSA Market Cap</div>
            <div className="market__cap__total__value">{rankData && rankData.mktCap !==0 ? `$${dollarUSLocale.format(rankData.mktCap)}` : "Not Available"}</div>
          </div>
          <div className="market__cap__ranking">
            <div className="market__cap__heading">Market Cap Ranking</div>
            {rankData && rankData.rankSort!=='Unranked'? <div className="market__cap__ranking__value"><span>{`#${rankData.rankSort}`}</span>/ {rankData.rankTotal}</div> : <div className="market__cap__ranking__value">Not Available</div> }
          </div>
          {userSession ? 
          <Link to={rankData && rankData.rankSort!=='Unranked' ? `/ranking?rank=${rankData.rankSort}&pag=${parseInt(Math.floor(rankData.rankSort/25))+1}`: window.location.pathname+window.location.search} className="market__cap__page__link">View Market Cap Ranking Details</Link>
          : <Link to={`${window.location.pathname}?id=${card._id}&cardNumber=${id}&login=true`} className="market__cap__page__link">View Market Cap Ranking Details</Link>
          }
        </div>
      </div>
    </div>
  );
}

export default Analysis;
