import React, { useState } from "react";
import axios from "axios";
import "./AverageSalesPrice.css";
import CanvasJSReact from "../../../../assets/canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
axios.defaults.withCredentials = true

function AverageSalesPrice() {

  const [averageSalesPrice, setAverageSalesPrice] = useState([]);
  const hasWindow = typeof window !== 'undefined';
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width
    };
  }

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  React.useEffect(() => {
    axios
      .get(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/market/getms1"
      )
      .then((res) => {
        if (res.data.error) {
          alert(res.data.message);
        } else {
          let temp = res.data.count;
          for (var j = 0; j < temp.length; j++) {
            temp[j].date = temp[j].date
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-");
          }
          setAverageSalesPrice(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const dataPointsJapAvgPrice = () => {
    const dataPointsJapAP = [];
    averageSalesPrice.map((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        const properDate = new Date(year, month - 1, day);
        return properDate;
      };
      dataPointsJapAP.push({
        x: toDate(item.date),
        y: parseInt(item.japanese),
      });
    });
    return dataPointsJapAP;
  };
  const dataPointsEngAvgPrice = () => {
    const dataPointsEngAP = [];
    averageSalesPrice.map((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        const properDate = new Date(year, month - 1, day);
        return properDate;
      };
      dataPointsEngAP.push({
        x: toDate(item.date),
        y: parseInt(item.english),
      });
    });
    return dataPointsEngAP;
  };
  const colors = CanvasJS.addColorSet("revenueColorSet", [
    "#2277c0",
    "#F6BA02",
  ]);
  const options = {
    colorSet: "revenueColorSet",
    animationEnabled: true,
    animationDuration: 2000,
    backgroundColor: "#08131f",
    theme: "none",
    title: {
      text: "Average Price(USD)",
      fontColor: "white",
      fontSize: 15,
      fontFamily: "Roboto",
      horizontalAlign: "left",
      padding: 20,
    },
    axisX: {
      margin: 10,
      padding: {
        top: 10,
      },
      labelFontColor: "#fff",
      tickLength: 20,
      tickColor: "#08131F",
      lineColor: "#365C9E",
      lineThickness: 1,
      valueFormatString: "YYYY MMM",
    },
    axisY: {
      margin: 10,
      labelFontColor: "#fff",
      includesZero: true,
      valueFormatString: "$#,###,###.##",
      tickLength: 20,
      minimum: 0,
      maximum: 500,
      tickColor: "#08131F",
      lineThickness: 6,
      lineColor: "#08131F",
    },
    toolTip: {
      content:
        "{name}:<br/> Date: <strong>{x}</strong><br/>Average Price: <strong>${y}</strong>",
    },
    legend: {
      cursor: "pointer",
      fontColor: "#fff",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: 16,
      markerMargin: 10,
      itemclick: function (e) {
        if (
          typeof e.dataSeries.visible === "undefined" ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      },
    },
    data: [
      {
        type: "spline",
        markerType: "circle",
        legendMarkerType: "circle",
        legendMarkerBorderThickness: 11,
        legendMarkerColor: "#2277c0",
        legendMarkerBorderColor: "#2277c0",
        markerSize: 1,
        showInLegend: true,
        name: "English Average Price",
        dataPoints: dataPointsEngAvgPrice(),
      },
      {
        type: "spline",
        markerType: "circle",
        legendMarkerType: "circle",
        legendMarkerBorderThickness: 11,
        legendMarkerColor: "#F6BA02",
        legendMarkerBorderColor: "#F6BA02",
        markerSize: 1,
        showInLegend: true,
        name: "Japanese Average Price",
        dataPoints: dataPointsJapAvgPrice(),
      },
    ],
  };
  const MobOptions = {
    colorSet: "revenueColorSet",
    animationEnabled: true,
    animationDuration: 2000,
    height: 220,
    backgroundColor: "#08131f",
    theme: "none",
    title: {
      text: "Average Price(USD)",
      fontColor: "white",
      fontSize: 11,
      fontFamily: "Roboto",
      horizontalAlign: "left",
      padding: 10,
    },
    axisX: {
      labelFontColor: "#fff",
      labelFontSize: 10,
      tickColor: "#08131F",
      lineColor: "#365C9E",
      lineThickness: 1,
      valueFormatString: "MMM 'YY",
    },
    axisY: {
      labelFontColor: "#fff",
      labelFontSize: 10,
      includesZero: true,
      valueFormatString: "$#,###,###.##",
      tickColor: "#08131F",
      lineColor: "#08131F",
    },
    toolTip: {
      content:
        "{name}:<br/> Date: <strong>{x}</strong><br/>Average Price: <strong>${y}</strong>",
    },
    legend: {
      cursor: "pointer",
      fontColor: "#fff",
      margin: {
        top: 10,
      },
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: 8,
      markerMargin: 10,
      itemclick: function (e) {
        if (
          typeof e.dataSeries.visible === "undefined" ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      },
    },
    data: [
      {
        type: "spline",
        lineThickness: 1,
        markerType: "circle",
        legendMarkerType: "circle",
        legendMarkerBorderThickness: 5,
        legendMarkerColor: "#2277c0",
        legendMarkerBorderColor: "#2277c0",
        markerSize: 1,
        showInLegend: true,
        name: "English Average Price",
        dataPoints: dataPointsEngAvgPrice(),
      },
      {
        type: "spline",
        lineThickness: 1,
        markerType: "circle",
        legendMarkerType: "circle",
        legendMarkerBorderThickness: 5,
        legendMarkerColor: "#F6BA02",
        legendMarkerBorderColor: "#F6BA02",
        markerSize: 1,
        showInLegend: true,
        name: "Japanese Average Price",
        dataPoints: dataPointsJapAvgPrice(),
      },
    ],
  };
  return (
    <div className="revenue__volume">
      <div className="box">
        <div className="heading__area">
          <div className="rv__heading">Average Sales Price</div>
          <span className="rv__description">
            <p>Track the overall market movement.</p>
            <p>This is a good indicator of the market direction.</p>
          </span>
        </div>
        <div className="graph__area">
          <div className="chart__background">
            {windowDimensions &&
              windowDimensions.width &&
              windowDimensions.width > 650 ? (
              <div className="revenue__chart">
                <CanvasJSChart
                  options={options}
                />
              </div>
            ) : (
              <div className="revenue__chart">
                <CanvasJSChart
                  options={MobOptions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AverageSalesPrice;
