import React from 'react';
import './MarketTrend.css';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import axios from 'axios';
import { formatDate } from '../../assets/canvasjs.min';
import { useHistory } from 'react-router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
axios.defaults.withCredentials = true

const MarketTrend = () => {
    // const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const history = useHistory();
    const [language, setLanguage] = React.useState("en");
    const [language2, setLanguage2] = React.useState("en");
    const [metaData, setMetaData] = React.useState([]);
    const [gainData, setGainData] = React.useState([]);
    const [lossData, setLossData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [cardIdLoader, setCardIdLoader] = React.useState(false);
    const dollarUSLocale = Intl.NumberFormat('en-US');


    React.useEffect(() => {
        setRefresh(!refresh);
    }, [])


    React.useEffect(() => {
        // setLossData(null);
        if (error) setError(false);
        axios.get(
            "" +
            process.env.REACT_APP_BACKEND_URL +
            `api/mtgl/getMetaData`,
        ).then((res) => {
            if (!res.data.error) {
                setMetaData(res.data.data.metadata);
                // setTotal(res.data.count);
                // setMetaData(res.data.data.metadata);

            }
            else {
                setError(true)
                console.log(res.data.message);
            }
        }
        ).catch((err) => {
            console.log(err);
            setError(true)
        });

        return () => { };
    }, [language2, refresh])

    React.useEffect(() => {
        setGainData(null);
        if (error) setError(false);
        axios.get(
            "" +
            process.env.REACT_APP_BACKEND_URL +
            `api/mtgl/gainTop10?filterLang=${language}`,
        ).then((res) => {
            if (!res.data.error) {
                setGainData(res.data.data);
            }
            else {
                setError(true)
                console.log(res.data.message);
            }
        }
        ).catch((err) => {
            console.log(err);
            setError(true)
        });

        return () => { };
    }, [language, refresh])




    React.useEffect(() => {
        setLossData(null);
        if (error) setError(false);
        axios.get(
            "" +
            process.env.REACT_APP_BACKEND_URL +
            `api/mtgl/lossTop10?filterLang=${language2}`,
        ).then((res) => {
            if (!res.data.error) {
                setLossData(res.data.data);
                // setTotal(res.data.count);
                // setMetaData(res.data.data.metadata);

            }
            else {
                setError(true)
                console.log(res.data.message);
            }
        }
        ).catch((err) => {
            console.log(err);
            setError(true)
        });

        return () => { };
    }, [language2, refresh])


    function CardIDAPI(object) {
        setCardIdLoader(true);
        console.log("A")
        console.log(object)
        axios.post("" + process.env.REACT_APP_BACKEND_URL + "api/cards/getCardbyVersionIDv2",
            // axios.post("http://localhost:7000/" + "api/cards/getCardByVersionID",
            { versionID: object.card_id}
        ).then((res) => {
            setCardIdLoader(false);
            console.log("B")
            if (res.data.error) {
                setError(true)
                console.log(res.data)
            } else {
                console.log(res.data);
                history.push("/product?id=" + res.data.data._id + "&cardNumber=" + object.card_id)
            }
        }).catch((err) => {
            setCardIdLoader(false);
            console.log(err);
            setError(true)
        });
    }



    return (
        <div className="market__trend">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={cardIdLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="market__trend__container">
                <BreadCrumb />
                <div className="market__trend__heading">
                    Market Trends
                    <div className="market__trend__update__date">
                        Latest Update: {metaData ? formatDate((metaData.update_time), "YYYY-MMM-DD H:mm") : null}
                    </div>
                </div>
                <div className="market__trend__heading__text">
                    TCG market is very volatile, as a +/- 15% change is possible with in a short period of time.
                    <br />
                    Any changes within the +/- 15% range will be considered typical market fluctuation, it will not be included in this section.
                    <br />
                    Popular Cards that consistently reaches higher price, over +/- 25% change within 30days will be included in this section.
                    <br />
                    Low Pop cards with +/- 25% change will also be included.
                </div>
                <div className="market__trend__gains__section">
                    <div className="market__trend__gains__section__heading__container">
                        <div className='market__trend__gains__section__heading__container__left'>
                            <div className="market__trend__gains__section__heading__text">Rocket Gains 🚀🚀🚀</div>
                            <div className="market__trend__gains__section__heading__info">Cards experiencing abnormal high sales volume and incremental sales price</div>
                        </div>
                        <div className='market__trend__gains__section__heading__container__right'>
                            <div className="market__trend__gains__section__buttons">
                                <div className="market__trend__gains__section__buttons__button" style={language === 'en' ? { backgroundColor: '#C38D33', border: 'none' } : null} onClick={() => setLanguage("en")}>English Cards</div>
                                <div className="market__trend__gains__section__buttons__button" style={language === 'jp' ? { backgroundColor: '#C38D33', border: 'none' } : null} onClick={() => setLanguage("jp")}>Japanese Cards</div>
                            </div>
                        </div>
                    </div>
                    <div className='market__trend__gains__section__top__3'>
                        {gainData && gainData.length > 0 ?
                            gainData.map((item, index) => {
                                if (index < 3)
                                    return (
                                        <div className='market__trend__gains__section__top__3__card' onClick={() => { sessionStorage.setItem("bread", "trend"); CardIDAPI(item)}}>
                                            {index === 0 ? <div class="ribbon"><span>1st</span></div> :
                                                index === 1 ? <div class="ribbon1"><span>2nd</span></div> :
                                                    index === 2 ? <div class="ribbon2"><span>3rd</span></div> : null}
                                            <div className='market__trend__gains__section__top__3__card__image'>
                                                <img src={item.image} alt="card" />
                                            </div>
                                            <div className='market__trend__gains__section__top__3__card__info'>
                                                <div className='market__trend__gains__section__top__3__card__info__name'>{item.fName}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__details'>{`${item.releaseYear} ${item.set_name} #${item.card_number} (${item.language})`}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__version'>{item.lName === "NaN" ? "Original" : item.lName}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__companygrade'>PSA 10</div>
                                                <div className='market__trend__gains__section__top__3__card__info__data'>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>TCGFish Estimate:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.target_price)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>Last Sold:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.target_last_sold)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>Past 3 Sold Avg:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.past_3_sold)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day Price Change:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value' style={{ color: (item.target_price - item.old_price) > 0 ? "#8DF13A" : "red" }}>{(item.target_price - item.old_price) > 0 ? '+ ' : '- '}${dollarUSLocale.format(item.target_price - item.old_price)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day % Change:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value' style={{ color: item.change_percent > 0 ? "#8DF13A" : "red" }}>{(item.change_percent < 0 ? `- ${(item.change_percent * -100).toFixed(2)}` : `+ ${((item.change_percent) * 100).toFixed(2)}`)}%</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day # Transactions:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>{item.target_volume}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })
                            :
                            <div className='mt__preloader__whole'>
                                <div className="mt__preloader__container">
                                    <div className="mt__preloader">
                                        <div className="mt__preloader">
                                            <div className="mt__preloader">
                                                <div className="mt__preloader">
                                                    <div className="mt__preloader">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='market__trend__gains__section__listing'>
                        <div className='market__trend__gains__section__listing__headings'>
                            <div className='market__trend__gains__section__listing__headings__heading'>Card</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '5%' }}>Rank</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '18%' }}>CardName</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Card Version</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Last Sold</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Last 3 Sold AVG</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '14%' }}>30 Day Price Change (%)</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>30 Day # Transactions</div>
                        </div>
                        {gainData && gainData.length > 0 ?
                            gainData.map((item, index) => {
                                if (index >= 3)
                                    return (
                                        <div className='market__trend__gains__section__listing__row' onClick={() => { sessionStorage.setItem("bread", "trend"); CardIDAPI(item)}}>
                                            <div className='market__trend__gains__section__listing__row__inner'>
                                                <div className='market__trend__gains__section__listing__row__img'>
                                                    <img src={item.image} alt="card" />
                                                </div>
                                                <div className='market__trend__gains__section__listing__row__value__rank'>#{index + 1}</div>
                                                <div className='market__trend__gains__section__listing__row__value' style={{ width: '14%', alignItems: 'flex-start' }}>
                                                    <div className='market__trend__gains__section__listing__row__value__name'>{item.fName}</div>
                                                    <div className='market__trend__gains__section__listing__row__value__details'>{`${item.releaseYear} ${item.set_name} #${item.card_number} (${item.language})`}</div>
                                                </div>
                                                <div className='market__trend__gains__section__listing__row__value' style={{ fontWeight: 'normal' }}>{item.lName === "NaN" ? "Original" : item.lName}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>${dollarUSLocale.format(item.target_last_sold)}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>${dollarUSLocale.format(item.past_3_sold)}</div>
                                                <div className='market__trend__gains__section__listing__row__value__change' style={{ color: (item.target_price - item.old_price) > 0 ? "#8DF13A" : "red" }}>{(item.target_price - item.old_price) > 0 ? '+ ' : '- '}${dollarUSLocale.format((item.target_price - item.old_price).toFixed(1))}{' (' + (item.change_percent * 100).toFixed(2) + '%)'}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>{item.target_volume}</div>
                                            </div>
                                        </div>
                                    )
                            })
                            :
                            <div className='mt__preloader__whole2'>
                                <div className="mt__preloader__container">
                                    <div className="mt__preloader">
                                        <div className="mt__preloader">
                                            <div className="mt__preloader">
                                                <div className="mt__preloader">
                                                    <div className="mt__preloader">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='market__trend__gains__section__listing__view__all'>
                        <div className='market__trend__gains__section__listing__view__all__btn'>View All {`>>`}</div>
                    </div>
                </div>
                <div className="market__trend__gains__section">
                    <div className="market__trend__gains__section__heading__container">
                        <div className='market__trend__gains__section__heading__container__left'>
                            <div className="market__trend__gains__section__heading__text">Top Losses 🦍🤝💪📈(💎🙌)</div>
                            <div className="market__trend__gains__section__heading__info">{`They once said: 'I like the card','Personal Collection','Great fundamental','I don't understand but I'm in','FOMO','Yolo`}</div>
                        </div>
                        <div className='market__trend__gains__section__heading__container__right'>
                            <div className="market__trend__gains__section__buttons">
                                <div className="market__trend__gains__section__buttons__button" style={language2 === 'en' ? { backgroundColor: '#C38D33', border: 'none' } : null} onClick={() => setLanguage2("en")}>English Cards</div>
                                <div className="market__trend__gains__section__buttons__button" style={language2 === 'jp' ? { backgroundColor: '#C38D33', border: 'none' } : null} onClick={() => setLanguage2("jp")}>Japanese Cards</div>
                            </div>
                        </div>
                    </div>
                    <div className='market__trend__gains__section__top__3'>
                        {lossData && lossData.length > 0 ?
                            lossData.map((item, index) => {
                                if (index < 3)
                                    return (
                                        <div className='market__trend__gains__section__top__3__card' onClick={() => { sessionStorage.setItem("bread", "trend"); CardIDAPI(item)}}>
                                            {index === 0 ? <div class="ribbon"><span>1st</span></div> :
                                                index === 1 ? <div class="ribbon1"><span>2nd</span></div> :
                                                    index === 2 ? <div class="ribbon2"><span>3rd</span></div> : null}
                                            <div className='market__trend__gains__section__top__3__card__image'>
                                                <img src={item.image} alt="card" />
                                            </div>
                                            <div className='market__trend__gains__section__top__3__card__info'>
                                                <div className='market__trend__gains__section__top__3__card__info__name'>{item.fName}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__details'>{`${item.releaseYear} ${item.set_name} #${item.card_number} (${item.language})`}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__version'>{item.lName === "NaN" ? "Original" : item.lName}</div>
                                                <div className='market__trend__gains__section__top__3__card__info__companygrade'>PSA 10</div>
                                                <div className='market__trend__gains__section__top__3__card__info__data'>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>TCGFish Estimate:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.target_price)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>Last Sold:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.target_last_sold)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>Past 3 Sold Avg:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>${dollarUSLocale.format(item.past_3_sold)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day Price Change:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value' style={{ color: (item.target_price - item.old_price) > 0 ? "#8DF13A" : "red" }}>{(item.target_price - item.old_price) > 0 ? '+ ' : '- '}${dollarUSLocale.format(item.target_price - item.old_price)}</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day % Change:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value' style={{ color: item.change_percent > 0 ? "#8DF13A" : "red" }}>{(item.change_percent < 0 ? `- ${(item.change_percent * -100).toFixed(2)}` : `+ ${((item.change_percent) * 100).toFixed(2)}`)}%</div>
                                                    </div>
                                                    <div className='market__trend__gains__section__top__3__card__info__data__row'>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__heading'>30 Day # Transactions:</div>
                                                        <div className='market__trend__gains__section__top__3__card__info__data__row__value'>{item.target_volume}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })
                            :
                            <div className='mt__preloader__whole'>
                                <div className="mt__preloader__container">
                                    <div className="mt__preloader">
                                        <div className="mt__preloader">
                                            <div className="mt__preloader">
                                                <div className="mt__preloader">
                                                    <div className="mt__preloader">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='market__trend__gains__section__listing'>
                        <div className='market__trend__gains__section__listing__headings'>
                            <div className='market__trend__gains__section__listing__headings__heading'>Card</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '5%' }}>Rank</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '18%' }}>CardName</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Card Version</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Last Sold</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>Last 3 Sold AVG</div>
                            <div className='market__trend__gains__section__listing__headings__heading' style={{ width: '14%' }}>30 Day Price Change (%)</div>
                            <div className='market__trend__gains__section__listing__headings__heading'>30 Day # Transactions</div>
                        </div>
                        {lossData && lossData.length > 0 ?
                            lossData.map((item, index) => {
                                if (index >= 3)
                                    return (
                                        <div className='market__trend__gains__section__listing__row' onClick={() => { sessionStorage.setItem("bread", "trend"); CardIDAPI(item)}}>
                                            <div className='market__trend__gains__section__listing__row__inner'>
                                                <div className='market__trend__gains__section__listing__row__img'>
                                                    <img src={item.image} alt="card" />
                                                </div>
                                                <div className='market__trend__gains__section__listing__row__value__rank'>#{index + 1}</div>
                                                <div className='market__trend__gains__section__listing__row__value' style={{ width: '14%', alignItems: 'flex-start' }}>
                                                    <div className='market__trend__gains__section__listing__row__value__name'>{item.fName}</div>
                                                    <div className='market__trend__gains__section__listing__row__value__details'>{`${item.releaseYear} ${item.set_name} #${item.card_number} (${item.language})`}</div>
                                                </div>
                                                <div className='market__trend__gains__section__listing__row__value' style={{ fontWeight: 'normal' }}>{item.lName === "NaN" ? "Original" : item.lName}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>${dollarUSLocale.format(item.target_last_sold)}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>${dollarUSLocale.format(item.past_3_sold)}</div>
                                                <div className='market__trend__gains__section__listing__row__value__change' style={{ color: (item.target_price - item.old_price) > 0 ? "#8DF13A" : "red" }}>{(item.target_price - item.old_price) > 0 ? '+ ' : '- '}${dollarUSLocale.format((item.target_price - item.old_price).toFixed(1))}{' (' + (item.change_percent * 100).toFixed(2) + '%)'}</div>
                                                <div className='market__trend__gains__section__listing__row__value'>{item.target_volume}</div>
                                            </div>
                                        </div>
                                    )
                            })
                            :
                            <div className='mt__preloader__whole2'>
                                <div className="mt__preloader__container">
                                    <div className="mt__preloader">
                                        <div className="mt__preloader">
                                            <div className="mt__preloader">
                                                <div className="mt__preloader">
                                                    <div className="mt__preloader">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='market__trend__gains__section__listing__view__all'>
                        <div className='market__trend__gains__section__listing__view__all__btn'>View All {`>>`}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketTrend;