import React from "react";
import "./HeroSection.css";
import img1 from "../../assets/images/Group.png";
import img2 from "../../assets/images/3.jpg";
import img3 from "../../assets/images/4.jpg";
import img4 from "../../assets/images/5.jpg";
import star1 from "../../assets/images/star1.png";
import star2 from "../../assets/images/star2.png";
import { Link } from "react-router-dom";
import MobileStoreButton from "react-mobile-store-button/lib/MobileStoreButton";

function HeroSection() {
  const [slider, setSlider] = React.useState(true);
  const iOSUrl = "https://apps.apple.com/pk/app/tcgfish/id1663269891";
  const androidUrl = "https://play.google.com/store/apps/details?id=com.tcgfish";

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setSlider(true);
  //   }, 5000);
  //   return () => clearTimeout(timeout);
  // }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSlider(!slider);
    }, 8000);
    return () => clearInterval(interval);
  }, [slider]);
  return (
    <>
      <div className="hero-container">
        {!slider ?
          <div className="hero__slider__section">
            <div className="hero__slider__content">
              <div className="hero__slider__text">
                <img className="star" src={star2} alt="IMG" />
                <img className="star1" src={star1} alt="IMG" />
                <img className="star2" src={star2} alt="IMG" />
                <img className="star3" src={star1} alt="IMG" />
                Better Insights<br />
                Better Collection
              </div>
              <Link to="/search" onClick={() => { sessionStorage.setItem("bread", "home") }} className="hero__slider__btn">
                <div>Search Catalogue</div>
              </Link>
            </div>
            <img
              className="hero__slider__image1"
              src={"../../assets/images/Group.png"}
              onError={(e) => (e.target.src = img1)}
              alt="IMG"
            />
          </div>
          :
          <div className="hero__slider__section">
            <div className="hero__slider__content">
              <div className="hero__slider__app">
                <img className="app_star" src={star2} alt="IMG" />
                <img className="app_star1" src={star1} alt="IMG" />
                <img className="app_star2" src={star2} alt="IMG" />
                <img className="app_star3" src={star1} alt="IMG" />
                <img className="app_star4" src={star2} alt="IMG" />
                <img className="app_star5" src={star1} alt="IMG" />
                <div className="hero__slider__app__heading">
                  Search + Scan + Data
                </div>
                <div className="hero__slider__app__text">
                  Advanced Graded Card Scanner helps capture the graded card's market value in seconds.<br /><br /> TCGFish now available on the App Store and Google Play Store.
                </div>
                <div className="hero__slider__app__links">
                <div className="hero__slider__app__link">
                  <MobileStoreButton
                    store="ios"
                    url={iOSUrl}
                    width="120px"
                    height="30px"
                    className="hero__slider__app__link__button__ios"
                  />
                </div>
                <div className="hero__slider__app__link">
                  <MobileStoreButton
                    store="android"
                    url={androidUrl}
                    width="130px"
                    height="50px"
                    className="hero__slider__app__link__button"
                  />
                </div>
                </div>
              </div>


            </div>
            <div className="hero__slider__app__images">
              <img className="hero__slider__app__image" src={img2} alt="IMG" />
              <img className="hero__slider__app__image" src={img3} alt="IMG" />
              <img className="hero__slider__app__image" src={img4} alt="IMG" />
            </div>
          </div>
        }
      </div>
      <div className="hero__slider__buttons">
        {slider ?
          <>
            <div className="hero__slider__button active" onClick={() => setSlider(true)}></div>
            <div className="hero__slider__button" onClick={() => setSlider(false)}></div>
          </> :
          <>
            <div className="hero__slider__button" onClick={() => setSlider(true)}></div>
            <div className="hero__slider__button active" onClick={() => setSlider(false)}></div>
          </>}</div>
    </>
  );
}

export default HeroSection;
