import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Ranking.css";
import empty from "../../assets/images/empty.png";
import axios from "axios";
import Pagination from "../../components/Pagination/Pagination";
import CheckBoxSet from "../../components/CheckBox/CheckBoxSet";
import Navbar from "../../components/NavbarRest/NavbarRest";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Maintenance from "../Maintenance/Maintenance";
axios.defaults.withCredentials = true

function Ranking(props) {

    const history = useHistory();
    const search = useLocation().search;
    const [error, setError] = React.useState(false);
    const [searchField, setsearchField] = React.useState(new URLSearchParams(search).get('q'));
    const [tempsearchField, settempsearchField] = React.useState(new URLSearchParams(search).get('q'));
    const [allSets, setallSets] = React.useState(null);
    const [CardIdLoader, setCardIdLoader] = React.useState(false);
    // const [setFilters, setsetFilters] = React.useState((new URLSearchParams(search).get('sets')) ? new URLSearchParams(search).get('sets').split(",") : []);
    // const [nameSortASC, setnameSortASC] = React.useState(((new URLSearchParams(search).get('n')) && (new URLSearchParams(search).get('n') === "true")) ? true : false);
    // const [japan, setjapan] = React.useState(((new URLSearchParams(search).get('j')) && (new URLSearchParams(search).get('j') === "false")) ? false : true);
    // const [english, setenglish] = React.useState(((new URLSearchParams(search).get('e')) && (new URLSearchParams(search).get('e') === "false")) ? false : true);
    // const [dateSort, setdateSort] = React.useState(((new URLSearchParams(search).get('d')) && (new URLSearchParams(search).get('d') === "true")) ? true : false);
    // const [popularity, setPopularity] = React.useState(((new URLSearchParams(search).get('p')) && (new URLSearchParams(search).get('p') === "false")) ? false : true);
    const [finalList, setfinalList] = React.useState(null);
    // const [showSets, setshowSets] = React.useState(false);
    const [refresh, setrefresh] = React.useState(false);
    const [currentPage, setcurrentPage] = React.useState(((new URLSearchParams(search).get('pag'))) ? parseInt(new URLSearchParams(search).get('pag')) : 1);
    const [totalCards, settotalCards] = React.useState(null);
    const [first, setFirst] = React.useState(true);
    const [card, setCard] = React.useState();
    const [totalMarketCap, setTotalMarketCap] = React.useState();
    const [lastUpdated, setLastUpdated] = React.useState();
    const [jumpToRank, setJumpToRank] = React.useState();
    const dollarUSLocale = Intl.NumberFormat('en-US');

    const cardsPerPage = 25;

    React.useEffect(() => {
        sessionStorage.setItem("searchLink", (window.location.pathname + window.location.search));
    }, [new URLSearchParams(search).get('q'), new URLSearchParams(search).get('pag')])


    React.useEffect(() => {
        setsearchField((new URLSearchParams(search).get('q')) ? new URLSearchParams(search).get('q') : "")
    }, [new URLSearchParams(search).get('q')])
    // React.useEffect(() => {
    //     setsetFilters((new URLSearchParams(search).get('sets')) ? new URLSearchParams(search).get('sets').split(",") : [])
    // }, [new URLSearchParams(search).get('sets')])
    // React.useEffect(() => {
    //     setnameSortASC(((new URLSearchParams(search).get('n')) && (new URLSearchParams(search).get('n') === "true")) ? true : false);
    // }, [new URLSearchParams(search).get('n')])
    // React.useEffect(() => {
    //     setjapan(((new URLSearchParams(search).get('j')) && (new URLSearchParams(search).get('j') === "false")) ? false : true);
    // }, [new URLSearchParams(search).get('j')])
    // React.useEffect(() => {
    //     setenglish(((new URLSearchParams(search).get('e')) && (new URLSearchParams(search).get('e') === "false")) ? false : true)
    // }, [new URLSearchParams(search).get('e')])
    // React.useEffect(() => {
    //     setPopularity(((new URLSearchParams(search).get('p')) && (new URLSearchParams(search).get('p') === "false")) ? false : true)
    // }, [new URLSearchParams(search).get('p')])
    // React.useEffect(() => {
    //     setdateSort(((new URLSearchParams(search).get('d')) && (new URLSearchParams(search).get('d') === "true")) ? true : false)
    // }, [new URLSearchParams(search).get('d')])
    React.useEffect(() => {
        setcurrentPage(((new URLSearchParams(search).get('pag'))) ? parseInt(new URLSearchParams(search).get('pag')) : 1)
    }, [new URLSearchParams(search).get('pag')])
    React.useEffect(() => {
        setJumpToRank(((new URLSearchParams(search).get('rank'))) ? parseInt(new URLSearchParams(search).get('rank')) : 1)
    }, [new URLSearchParams(search).get('rank')])


    React.useEffect(() => {
        if (window.document.getElementById(jumpToRank) !== null) {
            setTimeout(() => { window.document.getElementById(jumpToRank).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); }, 800)
        }
    }, [finalList, jumpToRank]);


    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (tempsearchField !== null && !first) {
                history.push("/ranking?q=" + tempsearchField + "&pag=" + 1)
            }
        }, 700);
        return () => clearTimeout(timeOutId);
    }, [tempsearchField]);

    React.useEffect(() => {
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + "api/ranks/getTotalMarketCap",
            )
            .then((res) => {
                if (res.data.error) {
                    setError(true)
                } else {
                    // settotalCards(res.data.count);
                    // setfinalList(res.data.data);
                    console.log(res.data.data)
                    setTotalMarketCap(res.data.data);
                    // setFirst(false);
                    // setError(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, []);

    React.useEffect(() => {
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + "api/ranks/getLastUpdated",
            )
            .then((res) => {
                if (res.data.error) {
                    setError(true)
                } else {
                    // settotalCards(res.data.count);
                    // setfinalList(res.data.data);
                    // console.log(res.data.data.lastUpdated)
                    setLastUpdated(res.data.data.lastUpdated);
                    // setFirst(false);
                    // setError(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, []);

    React.useEffect(() => {
        if (error) setError(false)
        setfinalList(null);
        axios
            .post(
                "" + process.env.REACT_APP_BACKEND_URL + "api/ranks/getAll",
                // "http://localhost:7000/" + "api/ranks/getAll",
                {
                    limit: cardsPerPage,
                    skip: currentPage - 1,
                    query: searchField || ""
                }
            )
            .then((res) => {
                console.log(res);
                if (res.data.error) {
                    setError(true)
                } else {
                    settotalCards(res.data.count);
                    setfinalList(res.data.data);
                    console.log(res.data)
                    setFirst(false);
                    setError(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, [searchField, currentPage, refresh]);

    // function getCard(version){
    //     axios
    //         .post(
    //             "" + process.env.REACT_APP_BACKEND_URL + "api/cards/getCardByVersionID",
    //             {
    //                 versionID: version
    //             }
    //         )
    //         .then((res) => {
    //             if (res.data.error) {
    //                 setError(true)
    //             } else {
    //                 setCard(res.data.data);
    //                 console.log(res.data.data);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setError(true)
    //         });
    // }

    function CardIDAPI(object) {
        setCardIdLoader(true);
        axios.post("" + process.env.REACT_APP_BACKEND_URL + "api/cards/getCardByVersionID",
            // axios.post("http://localhost:7000/" + "api/cards/getCardByVersionID",
            { object: object }
        ).then((res) => {
            setCardIdLoader(false);
            if (res.data.error) {
                setError(true)
            } else {
                history.push("/product?id=" + res.data.data._id + "&cardNumber=" + object.card_id)
            }
        }).catch((err) => {
            setCardIdLoader(false);
            console.log(err);
            setError(true)
        });
    }

    const handlePrevNav = () => {
        if (currentPage > 1) {
            history.push("/ranking?q=" + searchField + "&pag=" + (currentPage - 1))
        }
    }
    const handleNextNav = () => {
        if (currentPage < Math.ceil(totalCards / cardsPerPage)) {
            history.push("/ranking?q=" + searchField + "&pag=" + (currentPage + 1))
        }
    }

    function listItems() {
        if (finalList.length > 0) {
            return finalList.map((item) => {
                return (
                    <div onClick={() => { sessionStorage.setItem("bread", "ranking"); CardIDAPI(item); }}>
                        <div id={item.rankSort} className="rank__card__row">
                            <div className="rank__card">
                                <div key={item.id}>
                                    <div className="rank__card__data">
                                        <div className="rank__header__image">
                                            <img
                                                className="rank__card_img"
                                                src={item.image}
                                                onError={(e) => (e.target.src = empty)}
                                                alt="IMG"
                                            />
                                        </div>
                                        <div className="rank__header__rank">
                                            <div className="rank__card__ranking">{item.rankSort !== 'Unranked' ? `#${item.rankSort}` : 'Unranked'}</div>
                                        </div>
                                        <div className="rank__header__name">
                                            <p className="rank__card__name">
                                                {item.name}
                                            </p>
                                            <p className="rank__card__info">
                                                {((item.card_number) && (item.card_number !== "Missing")) ? (
                                                    <>
                                                        <span>{item.releaseYear}</span>
                                                        <span>{item.set_name}</span>
                                                        <span>#{item.card_number}</span>
                                                        <span>({item.language})</span>
                                                    </>
                                                ) : null}
                                            </p>
                                        </div>
                                        <div className="rank__header__version">
                                            <div className="rank__card__version">
                                                {item.sub_category}
                                            </div>
                                        </div>
                                        <div className="rank__header__pop">
                                            <div className="rank__card__pop">
                                                {item.psaPop}
                                            </div>
                                        </div>
                                        <div className="rank__header__mkt_cap">
                                            <div className="rank__card__mkt_cap">
                                                ${dollarUSLocale.format(item.mktCap)}
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        } else
            return (
                <span style={{ color: "white", fontSize: "20px", margin: "20px" }}>
                    No cards found
                </span>
            );
    }

    const paginate = (pageNumber) => {
        history.push("/ranking?q=" + searchField + "&pag=" + pageNumber)
        window.scrollTo(0, 0);
    };

    function Formatter(num) {
        if (Math.abs(num) > 999999999) {
            return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B';
        } else if (Math.abs(num) > 999999) {
            return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M';
        } else if (Math.abs(num) > 999) {
            return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
        } else
            return Math.sign(num) * Math.abs(num);
    }


    return (
        <div className="ranking">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={CardIdLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* <Navbar page="other" search="hide" /> */}
            {/* <Maintenance /> */}
            <div className="rank__page">
                <div className="rank__breadcrumb">
                    <BreadCrumb />
                </div>
                <div className="rank__search-main">
                    <div className="rank__page__heading">PSA Market Cap Ranking</div>
                    <div className="rank__search__container">
                        <input
                            id="rank_search_input"
                            type="search"
                            className="rank__search__bar"
                            placeholder="Filter by card name, number, set name, release year, language..."
                            value={tempsearchField}
                            onChange={(e) => {
                                settempsearchField(e.target.value);
                            }}
                        />
                        <div className="rank__update__info"><span>

                            <div className="rank__last__sold">
                                {
                                    // cardSales && lastSold ?
                                    "Latest Update: " +
                                    new Date(lastUpdated).toLocaleDateString("en-us", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    })
                                    // : null
                                }
                            </div>
                            {
                                totalMarketCap ?
                                    "Total Market Cap: " +
                                    Formatter(totalMarketCap)
                                    : null
                            }
                        </span>
                        </div>

                    </div>
                    <div className="page__navigation">
                        {currentPage > 1 ? <div className="nav__button" onClick={() => handlePrevNav()}>{`< Prev`}</div> : <div className="nav__button__disabled">{`< Prev`}</div>}
                        {currentPage < Math.ceil(totalCards / cardsPerPage) ? <div className="nav__button" onClick={() => handleNextNav()}>{`Next >`}</div> : <div className="nav__button__disabled">{`Next >`}</div>}
                    </div>
                </div>
                <div className="rank__Main">
                    {(error) ?
                        <>
                            <div className="rank__preloader__whole">
                                <div className="rank__error__message">
                                    ERROR Loading Page<br /><br />
                                    Please check the URL and reload again
                                </div>
                            </div>
                        </>
                        : <>
                            <div className="rank__container" style={(!finalList) ? { border: "0px" } : {}}>
                                {finalList
                                    ? <>
                                        <div className="rank__header__row">
                                            <div className="rank__header__image">Card</div>
                                            <div className="rank__header__rank">Ranking</div>
                                            <div className="rank__header__name">Card Name</div>
                                            <div className="rank__header__version">Card Version</div>
                                            <div className="rank__header__pop">Total PSA Population</div>
                                            <div className="rank__header__mkt_cap">Market Cap (USD)</div>
                                        </div>
                                        <div className="rank__listing">
                                            {listItems()}
                                        </div>
                                    </>
                                    : <>
                                        <div className="rank__preloader__whole">
                                            <div className="rank__preloader__container">
                                                <div className="rank__preloader">
                                                    <div className="rank__preloader">
                                                        <div className="rank__preloader">
                                                            <div className="rank__preloader">
                                                                <div className="rank__preloader">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div>
                                {totalCards ? (
                                    <div className="rank__page__container">
                                        <Pagination
                                            cardsPerPage={cardsPerPage}
                                            totalcards={totalCards}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    );
}

export default Ranking;
