import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./NavbarRest.css";
import NavSearch from "../NavSearch/NavSearch";
import logo from "../../assets/images/tcglogo.png";
import LoginModal from "../LoginModal/LoginModal";
import SignUpModal from "../SignUpModal/SignUpModal.js";
import ForgotModal from "../ForgotModal/ForgotModal.js";
import axios from "axios";
import ResetModal from "../ResetModal/ResetModal";
import { set } from "lodash";
axios.defaults.withCredentials = true;

function Navbar({ userSession, setUserSession }) {
  const history = useHistory();
  const search = useLocation().search;
  const [isLogin, setIsLogin] = React.useState(
    new URLSearchParams(search).get("login")
  );
  // const [open, setOpen] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openForgotModal, setOpenForgotModal] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [openResearchDropdown, setOpenResearchDropdown] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const hasWindow = typeof window !== "undefined";
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  // const [error, setError] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  const colors = [
    "#323639",
    "#202124",
    "#445760",
    "#2B373D",
    "#E3AB9A",
    "#FFAB40",
    "#78BB7B",
    "#5AB9C1",
    "#84ADEA",
    "#660D33",
    "#275D2B",
    "#A0144F",
    "#045B62",
    "#14539A",
    "#0D3562",
    "#6D41A1",
    "#462A68",
    "#FFAEBD",
  ];
  const [user, setUser] = React.useState({
    char: "",
    userName: "User",
    email: "example@email.com",
    color: "",
  });

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width,
    };
  }

  React.useEffect(() => {
    setIsLogin(
      new URLSearchParams(search).get("login")
        ? new URLSearchParams(search).get("login")
        : ""
    );
  }, [new URLSearchParams(search).get("login")]);

  React.useEffect(() => {
    if (!userSession) {
      axios
        .post("" + process.env.REACT_APP_BACKEND_URL + "api/user/check-session")
        .then((res) => {
          if (res.data.error) {
            setUserSession(false);
            console.log("User is not Logged In");
            console.log(res);
          } else {
            setUserSession(true);
            console.log("User is Logged In");
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  React.useEffect(() => {
    if (userSession) {
      axios
        .post("" + process.env.REACT_APP_BACKEND_URL + "api/user/user-info")
        .then((res) => {
          if (res.data.error) {
            console.log(res);
            console.log("User Data not found");
          } else {
            setUser({
              char: res.data.userInfo.fullname.charAt(0).toUpperCase(),
              userName: res.data.userInfo.fullname,
              email: res.data.userInfo.email,
              color: colors[Math.floor(Math.random() * colors.length)],
            });
            console.log("User Data Found");
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userSession]);

  React.useEffect(() => {
    setRefresh(!refresh);
  }, [user]);

  React.useEffect(() => {
    if (logout) {
      axios
        .post("" + process.env.REACT_APP_BACKEND_URL + "api/user/logout")
        .then((res) => {
          if (res.data.error) {
            console.log(res);
          } else {
            console.log(res);
            setUserSession(false);
            setLogout(false);
            setOpenDropdown(false);
            history.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [logout]);

  React.useEffect(() => {
    if (isLogin) {
      setOpenLoginModal(true);
    }
  }, [isLogin]);

  React.useEffect(() => {
    if (
      openLoginModal ||
      openSignUpModal ||
      openForgotModal ||
      openResetModal
    ) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
      document.body.style.paddingRight = "15px";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
      document.body.style.paddingRight = "unset";
    }
  }, [openLoginModal, openSignUpModal, openForgotModal, openResetModal]);

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  function Menu() {
    function DropdownItem(props) {
      if (window.location.pathname === props.path) {
        return <div className="rest__menu_item_disabled">{props.children}</div>;
      } else
        return (
          <div
            onClick={() => {
              sessionStorage.setItem("bread", "home");
              { userSession || props.path === '/search' || props.path === '/auction-monitor' || props.path === '/undervalue-bot' || props.path === '/market-trend' ? history.push(props.path) : setOpenLoginModal(true); };
            }}
            className="rest__menu_item"
          >
            {props.children}
          </div>
        );
    }
    return (
      <>
        {userSession ? (
          <div className="navbar__rest__buttonContainer__user">
            <DropdownItem path="/search">Search Catalogue</DropdownItem>
            <DropdownItem path="/auction-monitor">Auction Monitor</DropdownItem>
            <DropdownItem path="/undervalue-bot">Undervalue Bot</DropdownItem>
            <DropdownItem path="/market-trend">Market Trend</DropdownItem>
            <div
              onClick={() => {
                sessionStorage.setItem("bread", "home");
                setOpenResearchDropdown(true);
              }}
              className="rest__menu_item"
            >
              Research Tool <span><i className="fas fa-caret-down" /></span>
            </div>
            {/* <DropdownItem path="/market">Market Research Tool</DropdownItem>
            <DropdownItem path="/setvalue">Master Set Value</DropdownItem>
            <DropdownItem path="/ranking">Market Cap Ranking</DropdownItem> */}
            {/* <div className="login__button" onClick={() => setOpenLoginModal(true)}>Login</div> */}
            {/* <div className="signup__button" onClick={() => setOpenSignUpModal(true)}>Sign Up</div> */}
          </div>
        ) : (
          <div className="navbar__rest__buttonContainer">
            <DropdownItem path="/search">Search Catalogue</DropdownItem>
            <DropdownItem path="/auction-monitor">Auction Monitor</DropdownItem>
            <DropdownItem path="/undervalue-bot">Undervalue Bot</DropdownItem>
            <DropdownItem path="/market-trend">Market Trend</DropdownItem>
            <div
              onClick={() => {
                sessionStorage.setItem("bread", "home");
                setOpenResearchDropdown(true);
              }}
              className="rest__menu_item"
            >
              Research Tool <span><i className="fas fa-caret-down" /></span>
            </div>
            {/* <DropdownItem path="/market">Market Research Tool</DropdownItem>
            <DropdownItem path="/setvalue">Master Set Value</DropdownItem>
            <DropdownItem path="/ranking">Market Cap Ranking</DropdownItem> */}
            <div
              className="login__button"
              onClick={() => { setOpenLoginModal(true); setOpenDropdown(false) }}
            >
              Login
            </div>
            <div
              className="signup__button"
              onClick={() => { setOpenSignUpModal(true); setOpenDropdown(false) }}
            >
              Sign Up
            </div>
          </div>
        )}
      </>
    );
  }

  function NavList() {
    return (
      <div>
        <div className="media_mobile_list_icon" onClick={() => setOpenDropdown(true)}>
          <i className="fas fa-bars" />
        </div>
      </div>
    );
  }

  function Dropdown() {
    console.log("dropdown");
    function NavListDropdownItem(props) {
      if (window.location.pathname === props.path) {
        return <div className="dropdown__link__disabled">{props.children}</div>;
      } else
        return (
          <div
            onClick={() => {
              sessionStorage.setItem("bread", "home");
              { userSession || props.path === '/search' || props.path === '/auction-monitor' || props.path === '/undervalue-bot' || props.path === '/market-trend' ? history.push(props.path) : setOpenLoginModal(true); setOpenDropdown(false) }
            }}
            className="dropdown__link"
          >
            {props.children}
          </div>
        );
    }

    const wrapperRef = React.useRef(null);
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setOpenDropdown(false);
          // setOpenResearchDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [wrapperRef]);
    return (
      <div ref={wrapperRef} className="dropdown">
        {userSession ?
          <div className="dropdown__user">
            <div className="dropdown__profile">
              <div
                className="dropdown__profile__icon"
                style={{ backgroundColor: user.color }}
              >
                {/* <i className="profile__icon fas fa-user-circle" /> */}
                <div className="profile__icon">
                  {user ? (
                    user?.char
                  ) : (
                    <i className="profile__icon fas fa-user-circle" />
                  )}
                </div>
              </div>
            </div>
            <div className="dropdown__user__name">{user.userName}</div>
            <div className="dropdown__user__email">{user.email}</div>
          </div>
          : null}
        {windowDimensions &&
          windowDimensions.width &&
          windowDimensions.width < 550 ? (
          <div className="dropdown__links">
            <NavListDropdownItem path={"/search"}>
              Search Catalogue
            </NavListDropdownItem>
            <NavListDropdownItem path={"/auction-monitor"}>
              Auction Monitor
            </NavListDropdownItem>
            <NavListDropdownItem path={"/undervalue-bot"}>
              Undervalue Bot
            </NavListDropdownItem>
            <NavListDropdownItem path={"/market-trend"}>
              Market Trend
            </NavListDropdownItem>
            <NavListDropdownItem path={"/market"}>
              Market Research Tool
            </NavListDropdownItem>
            <NavListDropdownItem path={"/setvalue"}>
              Master Set Value
            </NavListDropdownItem>
            <NavListDropdownItem path={"/ranking"}>
              Market Cap Ranking
            </NavListDropdownItem>
          </div>
        ) : null}
        {userSession ?
          <div className="dropdown__links2">
            <div
              className="dropdown__link"
              onClick={() => {
                setOpenResetModal(true);
                setOpenDropdown(false);
              }}
            >
              Change Password
            </div>
            <div className="dropdown__link" onClick={() => setLogout(true)}>
              Logout
            </div>
          </div>
          :
          <div className="dropdown__links2">
            <div
              className="dropdown__link"
              onClick={() => { setOpenLoginModal(true); setOpenDropdown(false) }}
            >
              Login
            </div>
            <div className="dropdown__link" onClick={() => { setOpenSignUpModal(true); setOpenDropdown(false) }}>
              Sign Up
            </div>
          </div>}
      </div>
    );
  }
  function ResearchDropdown() {
    console.log("research__dropdown");
    function ResearchDropdownItem(props) {
      if (window.location.pathname === props.path) {
        return <div className="research__dropdown__link__disabled">{props.children}</div>;
      } else
        return (
          <div
            onClick={() => {
              sessionStorage.setItem("bread", "home");
              { userSession ? history.push(props.path) : setOpenLoginModal(true); setOpenResearchDropdown(false) }
            }}
            className="research__dropdown__link"
          >
            {props.children}
          </div>
        );
    }

    const wrapperRef = React.useRef(null);
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          // setOpenDropdown(false);
          setOpenResearchDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [wrapperRef]);
    return (
      <div ref={wrapperRef} className="research__dropdown" style={userSession ? { right: '10%' } : null}>
        {windowDimensions &&
          windowDimensions.width &&
          windowDimensions.width > 550 ? (
          <div className="research__dropdown__links">
            <ResearchDropdownItem path={"/market"}>
              Market Research Tool
            </ResearchDropdownItem>
            <ResearchDropdownItem path={"/setvalue"}>
              Master Set Value
            </ResearchDropdownItem>
            <ResearchDropdownItem path={"/ranking"}>
              Market Cap Ranking
            </ResearchDropdownItem>
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <nav className="rest__navbar">
      <div
        className={
          userSession
            ? "rest__navbar-container__user"
            : "rest__navbar-container"
        }
      >
        <Link to="/" className="rest__navbar-logo">
          <img
            src={logo}
            alt="LOGO"
            onError={(e) =>
            (e.target.src =
              "https://tcgfish.com/static/media/tcglogo.a50629f7.png")
            }
          />
        </Link>
        {/* <div className="rest__navbar-right">
          {userSession ? (
            <div
              className={
                windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width < 550
                  ? "rest__search__section"
                  : "rest__search__section__user"
              }
            >
              <NavSearch />
            </div>
          ) : (
            <div
              className={
                windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width < 550
                  ? "rest__search__section"
                  : "rest__search__section"
              }
            >
              <NavSearch />
            </div>
          )}
          {windowDimensions &&
          windowDimensions.width &&
          windowDimensions.width > 550 ? (
            <Menu />
          ) : <>
            {userSession ? null : <NavList />}
          </>
          }
        </div>
        {userSession ? (
          <div
            className="rest__navbar__profile"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div
              className="rest__navbar__profile__icon"
              style={{ backgroundColor: user.color }}
            >
              <div className="profile__icon">
                {user ? (
                  user.char
                ) : (
                  <i className="profile__icon fas fa-user-circle" />
                )}
              </div>
            </div>
          </div>
        ) : null}
        {openResearchDropdown ? <ResearchDropdown /> : null}
        {openDropdown ? <Dropdown /> : null}
      </div>
      {openLoginModal && !userSession && (
        <LoginModal
          openLoginModal={openLoginModal}
          setOpenLoginModal={setOpenLoginModal}
          setOpenSignUpModal={setOpenSignUpModal}
          setOpenForgotModal={setOpenForgotModal}
          setUserSession={setUserSession}
        />
      )}
      {openSignUpModal && (
        <SignUpModal
          openSignUpModal={openSignUpModal}
          setOpenSignUpModal={setOpenSignUpModal}
          setOpenLoginModal={setOpenLoginModal}
        />
      )}
      {openForgotModal && (
        <ForgotModal
          openForgotModal={openForgotModal}
          setOpenForgotModal={setOpenForgotModal}
          setOpenLoginModal={setOpenLoginModal}
        />
      )}
      {openResetModal && (
        <ResetModal
          openResetModal={openResetModal}
          setOpenResetModal={setOpenResetModal}
        />
      )} */}
      </div>
    </nav>
  );
}

export default Navbar;
