import React from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from "chart.js";
import './RadarChart.css';
import { Radar } from "react-chartjs-2";
import axios from 'axios';
import { fontSize } from "@mui/system";
axios.defaults.withCredentials = true

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);



export function RadarChart({ id }) {
    const [chartData, setChartData] = React.useState();
    // console.log(id);
    const hasWindow = typeof window !== "undefined";
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        return {
            width,
        };
    }
    React.useEffect(() => {
        axios
            .post(
                "" + process.env.REACT_APP_BACKEND_URL + "api/radar/getRadarData",
                {
                    card_id: id,
                }
            )
            .then((res) => {
                if (res.data.error) {
                    console.log(res.data.message);
                } else {
                    let temp = res.data.data;
                    // console.log(temp);
                    // console.log(id);
                    //   for (var j = 0; j < temp.length; j++) {
                    //     temp[j].date = temp[j].date
                    //       .slice(0, 10)
                    //       .split("-")
                    //       .reverse()
                    //       .join("-");
                    //   }

                    setChartData(temp);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);

    const normalization = (temp) => {
        let max = temp[0];
        temp.map((item) => {
            if (item > max) max = item;
        })
        // console.log(max);
    }
    const getData = () => {
        if (chartData) {
            let arr = [
                chartData.popularity,
                chartData.liquidity,
                chartData.affordability,
                chartData.gd,
                chartData.vintage
            ];
            normalization(arr);
            return arr;
        } else {
            return null;
        }
    }

    const getScore = () => {
        if (chartData) {
            let arr = [
                chartData.popularity,
                chartData.liquidity,
                chartData.affordability,
                chartData.gd,
                chartData.vintage
            ];
            let sum = 0;
            arr.map((item) => {
                sum += item;
            })
            // console.log(sum);
            return sum /5;
        } else {
            return null;
        }
    }
    const data = {
        labels: ["Popularity", "Liquidity", "Affordability", "Grade Difficulty", "Vintage"],
        responsive: true,
        height: '200',
        datasets: [
            {
                // label: "",
                data: getData(),
                borderWidth: 2,
                borderColor: '#CE8A16',
                pointBackgroundColor: '#CE8A16',
                // pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#CE8A16',
                lineTension: 0.5,
                fill: true,
                backgroundColor: "#CE8A1667",
                // borderColor: "rgba(0, 153, 247,1)",
                hoverBackgroundColor: "light-blue",
                hoverBorderColor: "blue",


            }
        ]
    };
    const roptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'white',
                }
            },
            tooltip: {
                bodyAlign: 'center',
            }
        },

        scales: {
            r: {
                backgroundColor: '#333E50',
                animate: 'true',
                grid: {
                    color: '#0E1628',
                    backgroundColor: 'grey',
                    circular: true
                },
                angleLines: {
                    color: '#0E1628',
                },
                ticks: {
                    display: false,
                },
                pointLabels: {
                    // callback: function(value, index, values) {
                    //     return '$' + value;
                    // }
                    color: 'white',
                    font: {
                        size: 14
                      }
                },
                beginAtZero: true,
                max: 100,
                min: 0,
                stepSize: 20,
            },

        },
    }
    return (
        <div className="RadarChart">
            {windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width < 550 ? (
                <>
                    
                    <Radar data={data}
                        width={windowDimensions.width}
                        options={roptions} />
                </>) : (
                <>
                <div className="radar__score">
                        <div className="radar__score__title">Score:</div>
                        <div className="radar__score__value">{getScore()} / 100</div>
                    </div>
                    <Radar data={data}
                        width={300}
                        height={250}
                        options={roptions} />
                </>)
            }
        </div>
    );
}