import React from "react";
import "./UnderValueBot2.css";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import image from "../../assets/images/auction__page__image.jpeg";
import empty from "../../assets/images/empty.webp";
import ebay from "../../assets/images/ebay.jpg";
import logo from "../../assets/images/tcglogo(1).png";
import { formatDate } from "../../assets/canvasjs.min";
import Pagination from "../../components/Pagination/Pagination";
// import data from '../../data/data.json'
// import { set } from 'lodash'
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { useRef } from "react";

function UnderValueBot() {
    // console.log(data);
    const hasWindow = typeof window !== "undefined";
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );
    const history = useHistory();
    const search = useLocation().search;
    const [refresh, setRefresh] = React.useState(false);
    const [sortByTime, setSortByTime] = React.useState("Ending Soonest");
    const [timeDropdown, setTimeDropdown] = React.useState(false);
    const [sortByPrice, setSortByPrice] = React.useState("Best Value");
    const [priceDropdown, setPriceDropdown] = React.useState(false);
    const [company, setCompany] = React.useState("Grader");
    const [companyDropdown, setCompanyDropdown] = React.useState(false);
    const [dataByCompany, setDataByCompany] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [metaData, setMetaData] = React.useState(null);
    const [currentPage, setcurrentPage] = React.useState(
        new URLSearchParams(search).get("pag")
            ? parseInt(new URLSearchParams(search).get("pag"))
            : 1
    );
    const [limit, setLimit] = React.useState(50);
    const [total, setTotal] = React.useState(0);
    const [completeTotal, setCompleteTotal] = React.useState(0);
    const [searchField, setsearchField] = React.useState(
        new URLSearchParams(search).get("q") ? new URLSearchParams(search).get("q") : ""
    );
    const [tempsearchField, settempsearchField] = React.useState(
        new URLSearchParams(search).get("q") ? new URLSearchParams(search).get("q") : ""
    );
    const dollarUSLocale = Intl.NumberFormat("en-US");
    // const [titleTime, setTitleTime] = React.useState(null);
    // const [titleName, setTitleName] = React.useState(null);

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        return {
            width,
        };
    }

    React.useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [hasWindow]);
    React.useEffect(() => {
        setsearchField(
            new URLSearchParams(search).get("q")
                ? new URLSearchParams(search).get("q")
                : ""
        );
    }, [new URLSearchParams(search).get("q")]);
    React.useEffect(() => {
        setcurrentPage(
            new URLSearchParams(search).get("pag")
                ? parseInt(new URLSearchParams(search).get("pag"))
                : 1
        );
    }, [new URLSearchParams(search).get("pag")]);

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (tempsearchField !== null) {
                history.push("/undervalue-bot?q=" + tempsearchField + "&pag=" + 1);
            }
        }, 700);
        return () => clearTimeout(timeOutId);
    }, [tempsearchField]);

    React.useEffect(() => {
        setcurrentPage(1);
        history.push("/undervalue-bot?pag=" + 1);
    }, [company]);

    React.useEffect(() => {
        setMetaData(null);
        if (error) setError(false);
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + `api/underValue/getMetaData`
            )
            .then((res) => {
                if (!res.data.error) {
                    setMetaData(res.data.data.metadata);
                } else {
                    setError(true);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true);
            });

        return () => { };
    }, [refresh]);

    React.useEffect(() => {
        if (error) setError(false);
        setData(null);
        const getData = async () => {
            setData(null);
            await axios
                .get(
                    "" +
                    process.env.REACT_APP_BACKEND_URL +
                    `api/underValue/getData?limit=${limit}&page=${currentPage}&filterCompany=${company === "Grader" || company === "ALL"
                        ? ""
                        : company.toLowerCase()
                    }&searchQuery=${searchField}&sortET=${sortByTime === "Ending Soonest"
                        ? "asc"
                        : sortByTime === "Newly Added"
                            ? "desc"
                            : ""
                    }&sortPAF=${sortByPrice === "High-Low"
                        ? "desc"
                        : sortByPrice === "Low-High"
                            ? "asc"
                            : ""
                    }`
                )
                .then((res) => {
                    if (!res.data.error) {
                        setData(res.data.data);
                        setTotal(res.data.count);
                        setCompleteTotal(res.data.totalCount);
                        console.log(res.data.data);
                    } else {
                        setError(true);
                        console.log(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setError(true);
                });
        };
        getData();
        setInterval(() => {
            getData();
        }, 900000);

        return () => { };
    }, [
        refresh,
        company,
        currentPage,
        limit,
        searchField,
        sortByTime,
        sortByPrice,
    ]);

    // React.useEffect(() => {
    //     if (sortByTime) { setSortByPrice(false) }
    // }, [sortByTime])

    // React.useEffect(() => {
    //     if (sortByPrice) { setSortByTime(false) }
    // }, [sortByPrice])

    React.useEffect(() => {
        let list = [];
        if (data) {
            data.filter((item) => {
                if (item.company.toLocaleLowerCase() === company.toLocaleLowerCase()) {
                    list.push(item);
                } else if (company === "Grader" || company === "ALL") {
                    list.push(item);
                }
            });
            // console.log(list);
            // if (sortByTime) {
            //     const sortedTimeList = list.sort(
            //         (a, b) =>
            //             new Date(a.endTime) -
            //             new Date(b.endTime)
            //     );
            //     // console.log(sortedTimeList);
            //     setDataByCompany(sortedTimeList);
            // }
            // if (sortByPrice) {
            //     const sortedPriceList = list.sort(function (a, b) {
            //         let x = a.avgMktValue.slice(1) + 0;
            //         let y = b.avgMktValue.slice(1) + 0;
            //         // console.log(x, y)
            //         return y - x;
            //     });
            //     setDataByCompany(sortedPriceList);
            // }
            setDataByCompany(list);
        }
    }, [company, data, sortByTime, sortByPrice]);

    const ref = useRef(null);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setCompanyDropdown(false);
                setTimeDropdown(false);
                setPriceDropdown(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ref]);

    const paginate = (pageNumber) => {
        if (tempsearchField === null)
            history.push("/undervalue-bot?pag=" + pageNumber);
        else
            history.push(
                "/undervalue-bot?q=" + tempsearchField + "&pag=" + pageNumber
            );
        window.scrollTo(0, 0);
    };

    // const breakStr = (string) => {
    //     let str = string.indexOf(')');
    //     let newStr = string.slice(0, str + 2) + '\n' + string.slice(str + 2);
    //     // let s = newStr;
    //     console.log(newStr)
    //     setTitleTime(string.slice(0, str + 2))
    //     setTitleName(string.slice(str + 2))
    //     return newStr;
    // }

    const getGradient = () => {
        let gradient = "";
        let n = Math.random();
        if (n < 0.4) gradient = "pink";
        else if (n < 0.8) gradient = "blue";
        else gradient = "gold";
        // console.log(gradient)
        return gradient;
    };

    const getMeasure = (measure) => {
        if (measure === 1) {
            return (
                <div className="ub__content__list__row__listing__info__links__link">
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                </div>
            );
        } else if (measure === 3) {
            return (
                <div className="ub__content__list__row__listing__info__links__link">
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                </div>
            );
        } else if (measure === 5) {
            return (
                <div className="ub__content__list__row__listing__info__links__link">
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                    <i
                        className="fa fa-thumbs-up"
                        style={{ marginRight: "5px", color: "white" }}
                    />
                </div>
            );
        }
    };

    const CompanyDropdown = () => {
        return (
            <div ref={ref} className="ub__content__top__filter__dropdown">
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setCompany("ALL");
                        setCompanyDropdown(false);
                    }}
                >
                    ALL
                </div>
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setCompany("PSA");
                        setCompanyDropdown(false);
                    }}
                >
                    PSA
                </div>
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setCompany("BGS");
                        setCompanyDropdown(false);
                    }}
                >
                    BGS
                </div>
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setCompany("CGC");
                        setCompanyDropdown(false);
                    }}
                >
                    CGC
                </div>
            </div>
        );
    };
    const PriceDropdown = () => {
        return (
            <div ref={ref} className="ub__content__top__filter__dropdown1">
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setSortByPrice("High-Low");
                        setSortByTime("Time Left");
                        setPriceDropdown(false);
                    }}
                >
                    High-Low
                </div>
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setSortByPrice("Low-High");
                        setSortByTime("Time Left");
                        setPriceDropdown(false);
                    }}
                >
                    Low-High
                </div>
            </div>
        );
    };
    const TimeDropdown = () => {
        return (
            <div ref={ref} className="ub__content__top__filter__dropdown2">
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setSortByTime("Ending Soonest");
                        setSortByPrice("Best Value");
                        setTimeDropdown(false);
                    }}
                >
                    Ending Soonest
                </div>
                <div
                    className="ub__content__top__filter__dropdown__item"
                    onClick={() => {
                        setSortByTime("Newly Added");
                        setSortByPrice("Best Value");
                        setTimeDropdown(false);
                    }}
                >
                    Newly Added
                </div>
            </div>
        );
    };
    React.useEffect(() => {
        const initializeEBaySmartLinks = () => {
            window._epn = {
                campaign: "5338993909",
                smartPopover: false,
            };
            const script = document.createElement("script");
            script.src = "https://epnt.ebay.com/static/epn-smart-tools.js";
            document.head.appendChild(script);
        };

        initializeEBaySmartLinks();
    }, []);
    return (

        <div className="undervalue__bot__page">
            <div className="undervalue__bot__container">
                <div className="ub__container__top">
                    <div className="ub__content__top__left">
                        <BreadCrumb />
                        <div className="ub__content__top__heading">
                            Undervalue{" "}
                            <span>
                                <img src={logo} />
                            </span>{" "}
                            Bot - Wallet Smasher 2.1
                        </div>
                        <div className="ub__content__top__right__update__time">
                            Last Update:{" "}
                            {metaData
                                ?
                                formatDate(
                                    new Date(metaData.update_time),
                                    "YYYY-MMM-DD H:mm"
                                )
                                : null}
                        </div>
                        {/* <div className='ub__content__top__filters'>
                            <div className='ub__content__top__filter' style={{ backgroundColor: companyDropdown || company != 'ALL' ? "#6165EA" : null }} onClick={() => setCompanyDropdown(!companyDropdown)}>{company}<span><i class='fas fa-chevron-down' /></span></div>
                            {companyDropdown ? CompanyDropdown() : null}
                            <div className='ub__content__top__filter' style={{ backgroundColor: priceDropdown || sortByPrice != 'Best Value' ? "#6165EA" : null }} onClick={() => setPriceDropdown(!priceDropdown)}>{sortByPrice}<span><i class='fas fa-chevron-down' /></span></div>
                            {priceDropdown ? PriceDropdown() : null}
                            <div className='ub__content__top__filter' style={{ backgroundColor: timeDropdown || sortByTime != 'Time Left' ? "#6165EA" : null }} onClick={() => setTimeDropdown(!timeDropdown)}>{sortByTime}<span><i class='fas fa-chevron-down' /></span></div>
                            {timeDropdown ? TimeDropdown() : null}
                            {company != "ALL" ||sortByPrice != "Best Value" || sortByTime != "Time Left" ? <div className='ub__content__top__filter__clear' onClick={() => {setCompany("ALL"); setSortByPrice("Best Value"); setSortByTime("Time Left"); settempsearchField(''); setsearchField('')}}><span><i class='fas fa-close' /></span>Clear Queries</div> : null }
                        </div> */}
                    </div>
                    <div className="ub__content__top__right">
                        <div className="ub__content__top__right__update">
                            <div
                                className="ub__content__top__right__update__icon"
                                onClick={() => setRefresh(!refresh)}
                            >
                                <i className="fa fa-refresh" />
                                <span>Refresh Page</span>
                            </div>
                            {/* <div className='ub__content__top__right__update__time'>
                                Last Update: {metaData ? formatDate(new Date(metaData.update_time), "YYYY-MMM-DD H:mm") : null}
                            </div> */}
                        </div>
                        <div className="ub__content__top__right__update__info">
                            <div className="ub__content__top__right__update__info__row_u">
                                For information and education purposes only.
                            </div>
                            <div className="ub__content__top__right__update__info__row_u">
                                This page does not contain any financial advice.
                            </div>
                            <div className="ub__content__top__right__update__info__row_u">
                                All information used are public information, we do not own any
                                of the content displayed below.
                            </div>
                            <div className="ub__content__top__right__update__info__row_u">
                                TCGFish is an eBay Parnet Network affiliate. If you click on the affiliated links, and make a purchase, <br />TCGFish may receive a commission.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ub__container__content">
                <div className="ub__container__content__info">
                    <div className="ub__container__content__info__meta">
                        FishBot has gone fishing through{` `}
                        <strong>{metaData ? metaData.auction_total_count : null}</strong>
                        {` `}eBay listings. Fish Bot caught{` `}
                        <strong>{metaData ? metaData.auction_deal_count : null}</strong>
                        {` `}Deals. Good Deal rate at{` `}
                        <strong>
                            {metaData
                                ? (
                                    (metaData.auction_deal_count /
                                        metaData.auction_total_count) *
                                    100
                                ).toFixed(2)
                                : null}
                            %.
                        </strong>
                        {` `}Enjoy &#127843;!
                    </div>
                </div>

                <div className="ub__container__content__info__thumb__info">
                    <div className="ub__container__content__info__thumb__info__column"><div><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /></div><div>Good Deal</div></div>
                    <div className="ub__container__content__info__thumb__info__column"><div><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /></div><div>Great Deal</div></div>
                    <div className="ub__container__content__info__thumb__info__column" style={{ width: '40%' }}><div><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /><i className="fas fa-thumbs-up" style={{ marginRight: '5px' }} /></div><div>Amazing Deal</div></div>
                </div>

                {/* <div className='ub__container__content__info__info'>All information used are public information, we do not own any of the content displayed below.</div> */}
                <div className="ub__content__search__section">
                    {/* <input type='text' placeholder='Search your keywords' className='ub__content__search__bar' /> */}
                    <input
                        // id="shop_search_input"
                        type="search"
                        className="ub__content__search__bar"
                        placeholder="Search your keywords"
                        value={tempsearchField}
                        onChange={(e) => {
                            settempsearchField(e.target.value);
                        }}
                    />
                    {tempsearchField ? (
                        <i
                            className="fa fa-close ub__content__search___bar__close"
                            onClick={() => settempsearchField("")}
                        />
                    ) : (
                        <i className="fa fa-search ub__content__search___bar__close" />
                    )}
                    <div className="ub__content__search__found">
                        Selected Listings {total}/{completeTotal}
                    </div>
                    <div className="ub__content__top__filters">
                        <div
                            className="ub__content__top__filter"
                            style={{
                                backgroundColor:
                                    companyDropdown || company != "Grader" ? "#6165EA" : null,
                                width: "70px",
                            }}
                            onClick={() => setCompanyDropdown(!companyDropdown)}
                        >
                            {company}
                            <span>
                                <i class="fas fa-chevron-down" />
                            </span>
                        </div>
                        {companyDropdown ? CompanyDropdown() : null}
                        <div
                            className="ub__content__top__filter"
                            style={{
                                backgroundColor:
                                    priceDropdown || sortByPrice != "Best Value"
                                        ? "#6165EA"
                                        : null,
                                width: "100px",
                            }}
                            onClick={() => setPriceDropdown(!priceDropdown)}
                        >
                            {sortByPrice}
                            <span>
                                <i class="fas fa-chevron-down" />
                            </span>
                        </div>
                        {priceDropdown ? PriceDropdown() : null}
                        <div
                            className="ub__content__top__filter"
                            style={{
                                backgroundColor:
                                    timeDropdown || sortByTime != "Time Left"
                                        ? "#6165EA"
                                        : null,
                                width: "140px",
                            }}
                            onClick={() => setTimeDropdown(!timeDropdown)}
                        >
                            {sortByTime}
                            <span>
                                <i class="fas fa-chevron-down" />
                            </span>
                        </div>
                        {timeDropdown ? TimeDropdown() : null}
                        <div
                            className="ub__content__top__filter__clear"
                            style={
                                company != "Grader" ||
                                    sortByPrice != "Best Value" ||
                                    sortByTime != "Time Left"
                                    ? null
                                    : { color: "#444", cursor: "default" }
                            }
                            onClick={() => {
                                setCompany("Grader");
                                setSortByPrice("Best Value");
                                setSortByTime("Time Left");
                                settempsearchField("");
                                setsearchField("");
                            }}
                        >
                            <span>
                                <i class="fas fa-close" />
                            </span>
                            Restore Defaults
                        </div>
                    </div>
                </div>
                <div className="ub__container__content__listing">
                    {data && dataByCompany
                        ? dataByCompany.map((item, index) => {
                            // let str = item.cardTitle.indexOf(')');
                            // let TitleTime = item.cardTitle.slice(0, str + 2);
                            // let TitleName = item.cardTitle.slice(str + 2);
                            // let endTime = item.endTime.slice(0, -6);
                            return (
                                <a key={index} href={item.ebayLink} target="_blank" className="ub__content__list__row">
                                    <a href={item.ebayLink} target="_blank" className="ub__content__list__row__img">
                                        {windowDimensions &&
                                            windowDimensions.width &&
                                            windowDimensions.width < 650
                                            ? (<div className="ub__content__list__row__listing__info__links">
                                                <div
                                                    className="ub__content__list__row__listing__info__link"
                                                    style={{ backgroundColor: "#fff" }}
                                                >
                                                    <a
                                                        href={item.ebayLink}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <img src={ebay} />
                                                    </a>
                                                </div>
                                            </div>)
                                            : null
                                        }
                                        <img src={item.imageLink} />
                                    </a>
                                    <div className="ub__content__list__row__listing__info">
                                        <div className="ub__content__list__row__listing__info__header">
                                            <div className="ub__content__list__row__listing__info__title">
                                                {item.cardTitle}
                                            </div>
                                        </div>
                                        {windowDimensions &&
                                            windowDimensions.width &&
                                            windowDimensions.width > 650 ? (
                                            <div className="ub__content__list__row__listing__info__links">
                                                <div
                                                    className="ub__content__list__row__listing__info__link"
                                                    style={{ backgroundColor: "#fff" }}
                                                >
                                                    <a
                                                        href={item.ebayLink}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <img src={ebay} />
                                                    </a>
                                                </div>
                                                <div className="ub__content__list__row__listing__info__link">
                                                    <a
                                                        href={item.ebayLink}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <span>
                                                            <i class="fas fa-gavel" />
                                                        </span>
                                                        Bid Now
                                                    </a>
                                                </div>
                                                <div className="ub__content__list__row__listing__info__link">
                                                    <a
                                                        href={item.website}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <span>
                                                            <img src={logo} />
                                                        </span>
                                                        Card Data
                                                    </a>
                                                </div>
                                                <div className="ub__content__list__row__listing__info__link2">
                                                    {getMeasure(item.measure)}
                                                </div>
                                            </div>
                                        )
                                            : <div className="ub__content__list__row__listing__info__links" >
                                                <div className="ub__content__list__row__listing__info__link3">
                                                    <a
                                                        href={item.ebayLink}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <span>
                                                            <i class="fas fa-gavel" />
                                                        </span>
                                                        Bid Now
                                                    </a>
                                                </div>
                                                <div className="ub__content__list__row__listing__info__link3">
                                                    <a
                                                        href={item.website}
                                                        target="_blank"
                                                        className="ub__content__list__row__listing__info__links__link"
                                                    >
                                                        <span>
                                                            <img src={logo} />
                                                        </span>
                                                        Card Data
                                                    </a>
                                                </div>
                                                <div className="ub__content__list__row__listing__info__link3">
                                                    {windowDimensions &&
                                                        windowDimensions.width &&
                                                        windowDimensions.width > 650
                                                        ? getMeasure(item.measure) : item.timeLeft.slice(0, item.timeLeft.length - 4)}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="ub__content__list__row__listing__info__links">
                                        <div className="ub__content__list__row__listing__info__link">
                                            <a
                                                href={item.ebayLink}
                                                target="_blank"
                                                className="ub__content__list__row__listing__info__links__link"
                                            >
                                                <span>
                                                    <i class="fas fa-gavel" />
                                                </span>
                                                Bid Now
                                            </a>
                                        </div>
                                        <div className="ub__content__list__row__listing__info__link">
                                            <a
                                                href={item.website}
                                                target="_blank"
                                                className="ub__content__list__row__listing__info__links__link"
                                            >
                                                <span>
                                                    <img src={logo} />
                                                </span>
                                                Card Data
                                            </a>
                                        </div>
                                        <div className="ub__content__list__row__listing__info__link2">
                                            {getMeasure(item.measure)}
                                        </div>
                                    </div> */}
                                    {windowDimensions &&
                                        windowDimensions.width &&
                                        windowDimensions.width > 650
                                        ? (<div className="ub__content__list__row__time__info">
                                            <div className="ub__content__list__row__time__info__item">
                                                <div className="ub__content__list__row__time__info__item__header">
                                                    <span>
                                                        <i class="fa fa-clock" />
                                                    </span>
                                                    Time Left
                                                </div>
                                                <a
                                                    href={item.ebayLink}
                                                    target="_blank"
                                                    className="ub__content__list__row__time__info__item__value"
                                                >
                                                    {item.timeLeft.slice(0, item.timeLeft.length - 4)}
                                                </a>
                                            </div>
                                            <div className="ub__content__list__row__time__info__item">
                                                <div className="ub__content__list__row__time__info__item__header">
                                                    <span>
                                                        <img src={logo} />
                                                    </span>
                                                    Market Price
                                                </div>
                                                <a
                                                    href={item.website}
                                                    target="_blank"
                                                    className="ub__content__list__row__time__info__item__value"
                                                    style={{ color: "#FFD700" }}
                                                >
                                                    ${dollarUSLocale.format(item.avgMktValue.slice(1))}
                                                </a>
                                            </div>
                                            <div className="ub__content__list__row__time__info__item">
                                                <div className="ub__content__list__row__time__info__item__header">
                                                    Current Bid Price
                                                </div>
                                                <a
                                                    href={item.ebayLink}
                                                    target="_blank"
                                                    className="ub__content__list__row__time__info__item__value"
                                                >
                                                    ${dollarUSLocale.format(item.currentPrice.slice(1))}
                                                </a>
                                            </div>
                                        </div>)
                                        : (<div className="ub__content__list__row__time__info">
                                            <div className="ub__content__list__row__time__info__item2">
                                                <i class="fa fa-clock" />Current Bid Price
                                            </div>
                                            <div className="ub__content__list__row__time__info__item2">
                                                <img src={logo} />Market Price
                                            </div>
                                            <div className="ub__content__list__row__time__info__item2">
                                                TCGFish Rating
                                            </div>
                                            <a className="ub__content__list__row__time__info__item3" href={item.ebayLink} target="_blank">
                                                ${dollarUSLocale.format(item.currentPrice.slice(1))}
                                            </a>
                                            <a className="ub__content__list__row__time__info__item3" style={{ color: "#FFD700" }} href={item.website} target="_blank">
                                                ${dollarUSLocale.format(item.avgMktValue.slice(1))}
                                            </a>
                                            <a className="ub__content__list__row__time__info__item3" href={item.ebayLink} target="_blank">
                                                {getMeasure(item.measure)}
                                            </a>
                                        </div>)
                                    }
                                </a>
                            );
                        })
                        : null}
                </div>
                {total ? (
                    <div className="ub__content__pagination">
                        <Pagination
                            cardsPerPage={limit}
                            totalcards={total}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>
                ) : null}
            </div>
        </div>
        // <div className='undervalue__bot__page'>
        //     <div className='undervalue__bot__container'>
        //         <BreadCrumb />
        //         <div className='ub__content'>
        //             <div className='ub__content__top'>
        //                 <div className='ub__content__top__left'>
        //                     {/* <div className='ub__content__top__image'>
        //                         <img src={image} alt='2xm' />
        //                     </div> */}
        //                     <div className='ub__content__top__header'>
        //                         <div className='ub__content__top__heading'>Undervalue Bot - Wallet Smasher 2.1</div>
        //                         <div className='ub__content__top__companies'>
        //                             <div className='ub__content__top__company' style={{ backgroundColor: company === 'ALL' ? '#C38D33' : null }} onClick={() => setCompany('ALL')}>ALL</div>
        //                             <div className='ub__content__top__company' style={{ backgroundColor: company === 'PSA' ? '#C38D33' : null }} onClick={() => setCompany('PSA')}>PSA</div>
        //                             <div className='ub__content__top__company' style={{ backgroundColor: company === 'BGS' ? '#C38D33' : null }} onClick={() => setCompany('BGS')}>BGS</div>
        //                             <div className='ub__content__top__company' style={{ backgroundColor: company === 'CGC' ? '#C38D33' : null }} onClick={() => setCompany('CGC')}>CGC</div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className='ub__content__top__right'>
        //                     <div className='ub__content__top__right__update'>
        //                         <div className='ub__content__top__right__update__icon' onClick={() => setRefresh(!refresh)}>
        //                             <i className='fa fa-refresh' />
        //                         </div>
        //                         <div className='ub__content__top__right__update__time'>
        //                             Last Update: {metaData ? formatDate(new Date(metaData.update_time), "YYYY-MMM-DD H:mm") : null}
        //                             {/* {formatDate(new Date("2023-05-18"), "YYYY-MMM-DD H:mm")} */}
        //                         </div>
        //                     </div>
        //                     <div className='ub__content__top__right__update__info'>
        //                         <div className='ub__content__top__right__update__info__row_u'>For information and education purposes only.</div>
        //                         <div className='ub__content__top__right__update__info__row_u'>This page does not contain any financial advice.</div>

        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='ub__content__info' style={{ color: '#FFD8BD' }}>All information used are public information, we do not own any of the content displayed below.</div>
        //             <div className='ub__content__info__meta'>Searched for{` `}<strong>{metaData ? metaData.auction_total_count : null}</strong>{` `}Amount of listings ending in 12 hours and found{` `}<strong>{metaData ? metaData.auction_deal_count : null}</strong>{` `}potential good deals, good deal rate is{` `}<strong>{metaData ? ((metaData.auction_deal_count/metaData.auction_total_count)*100).toFixed(2) : null}%</strong></div>
        //             <div className='ub__content__search__section'>
        //                 {/* <input type='text' placeholder='Search your keywords' className='ub__content__search__bar' /> */}
        //                 <input
        //                     // id="shop_search_input"
        //                     type="search"
        //                     className="ub__content__search__bar"
        //                     placeholder="Search your keywords"
        //                     value={tempsearchField}
        //                     onChange={(e) => {
        //                         settempsearchField(e.target.value);
        //                     }}
        //                 />
        //                 <div className='ub__content__search__sort'>
        //                     <div className='ub__content__search__sort__button' style={sortByTime ? { backgroundColor: '#C38D33', color: '#fff' } : null} onClick={() => setSortByTime(!sortByTime)}>Sort by Time Left<span>(Ending Soonest)</span></div>
        //                     <div className='ub__content__search__sort__button' style={sortByPrice ? { backgroundColor: '#C38D33', color: '#fff' } : null} onClick={() => setSortByPrice(!sortByPrice)}>Sort by Market Price<span>(High to Low)</span></div>
        //                 </div>
        //             </div>
        //             <div className='ub__content__list__section'>
        //                 {data && dataByCompany ?

        //                     dataByCompany.map((item, index) => {
        //                         let str = item.cardTitle.indexOf(')');
        //                         let TitleTime = item.cardTitle.slice(0, str + 2);
        //                         let TitleName = item.cardTitle.slice(str + 2);
        //                         let endTime = item.endTime.slice(0, -6);
        //                         return (
        //                             <div className='ub__content__list__row'>
        //                                 <div className='ub__content__list__row__img'>
        //                                     <img src={item.imageLink} alt='2xm' />
        //                                 </div>
        //                                 <div className='ub__content__list__row__listing__info'>
        //                                     <div className='ub__content__list__row__listing__info__header'>
        //                                         <div className='ub__content__list__row__listing__info__title'><span>{TitleTime}</span><br />{TitleName}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__listing__info__links'>
        //                                         <div className='ub__content__list__row__listing__info__ebay'>
        //                                             <div className='ub__content__list__row__listing__info__links__header'>eBay Link</div>
        //                                             <a href={item.ebayLink} target='_blank' className='ub__content__list__row__listing__info__links__link' style={{ color: '#0F6DE8' }}>Go to eBay Listing Page</a>
        //                                         </div>
        //                                         <div className='ub__content__list__row__listing__info__price__history'>
        //                                             <div className='ub__content__list__row__listing__info__links__header'>TCGFish Price History</div>
        //                                             <a href={item.website} className='ub__content__list__row__listing__info__links__link' style={{ color: '#f4c313' }}>See Price History Page</a>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className='ub__content__list__row__bid__info'>
        //                                     <div className='ub__content__list__row__bid__info__row'>
        //                                         <div className='ub__content__list__row__bid__info__header'>Bid #</div>
        //                                         <div className='ub__content__list__row__bid__info__value'>{item.bids}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__bid__info__row'>
        //                                         <div className='ub__content__list__row__bid__info__header'>Current Bid Price</div>
        //                                         <div className='ub__content__list__row__bid__info__value'>{item.currentPrice}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__bid__info__row'>
        //                                         <div className='ub__content__list__row__bid__info__header'>Fair Market Value</div>
        //                                         <div className='ub__content__list__row__bid__info__value'>{item.avgMktValue}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__bid__info__row'>
        //                                         <div className='ub__content__list__row__bid__info__header'>Break Even Bid Price</div>
        //                                         <div className='ub__content__list__row__bid__info__value' style={{ color: '#31DE2B' }}>{item.beBid}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__bid__info__row'>
        //                                         <div className='ub__content__list__row__bid__info__header'>Card Language</div>
        //                                         <div className='ub__content__list__row__bid__info__value'>{item.language}</div>
        //                                     </div>
        //                                 </div>
        //                                 <div className='ub__content__list__row__time__info'>
        //                                     <div className='ub__content__list__row__time__info__time__left'>
        //                                         <div className='ub__content__list__row__time__info__time__left__header'>Time Left</div>
        //                                         <div className='ub__content__list__row__time__info__time__left__value'>{item.timeLeft}</div>
        //                                     </div>
        //                                     <div className='ub__content__list__row__time__info__time__end'>
        //                                         <div className='ub__content__list__row__time__info__time__end__header'>End Time (EST)</div>
        //                                         <div className='ub__content__list__row__time__info__time__end__value'>
        //                                             {/* {formatDate(new Date(item.endTime), "YYYY-MMM-DD H:mm")} */}
        //                                             {/* 2023-05-18 */}
        //                                             {endTime}
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         )
        //                     })
        //                     : null
        //                 }
        //             </div>
        //             {total ?
        //                 <div className='ub__content__pagination'>
        //                     <Pagination cardsPerPage={limit} totalcards={total} paginate={paginate} currentPage={currentPage} />
        //                 </div>
        //                 : null}
        //         </div>
        //     </div>
        // </div>
    );
}

export default UnderValueBot;
