import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./MasterSetValue.css"
import axios from "axios";
import Navbar from "../../components/NavbarRest/NavbarRest";
import MasterSetValueChart from "../../components/MasterSetValueChart/MasterSetValueChart";
import CheckBoxSetMSV from "../../components/CheckBox/CheckBoxSetMSV";
import MarketSetValueTopCards from "../../components/MarketSetValueTopCards/MarketSetValueTopCards";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import Maintenance from "../Maintenance/Maintenance";
axios.defaults.withCredentials = true

export default function MasterSetValue() {
    const history = useHistory();
    const search = useLocation().search;
    const [error, setError] = React.useState(false);
    const [setFilters, setsetFilters] = React.useState((new URLSearchParams(search).get('setname')) ? new URLSearchParams(search).get('setname') : null);
    const [allsets, setallsets] = React.useState(null);
    const [showsets, setshowsets] = React.useState(false);
    const [refresh, setrefresh] = React.useState(false);
    const [forceclose, setForceClose] = React.useState(false);

    React.useEffect(() => {
        setsetFilters((new URLSearchParams(search).get('setname')) ? new URLSearchParams(search).get('setname') : null)
    }, [new URLSearchParams(search).get('setname')])

    React.useEffect(() => {
        if (error) setError(false);
        axios.get(
            "" +
            process.env.REACT_APP_BACKEND_URL +
            "api/msv/getSets",
        ).then((res) => {
            if (!res.data.error) {
                setallsets(res.data.data);
                if (setFilters === null) {
                    history.push("/setvalue?setname=" + res.data.data[0])
                }
                else if (res.data.data.indexOf(setFilters) === -1) {
                    setError(true);
                }
            }
            else {
                setError(true)
                console.log(res.data.message);
            }
        }
        ).catch((err) => {
            console.log(err);
            setError(true)
        });
    }, [])

    React.useEffect(() => {
        setshowsets(false);
    }, [forceclose]);

    function changeSets(item) {
        history.push("/setvalue?setname=" + item);
        setrefresh(!refresh);
        setForceClose(!forceclose);
    }
    
    if (error) return (
        <div className="MasterSetValue">
            {/* <Navbar /> */}
            <div className="MasterSetValue_NavSpace"></div>
            <div className="MasterSetValue_Container">
                <div className="MasterSetValue_Error_Message">
                    ERROR Loading Page<br /><br />
                    Please check the URL and reload again
                </div>
            </div>
        </div >
    )
    return (
        <div className="MasterSetValue">
            {/* <Navbar /> */}
            {/* <Maintenance /> */}
            <div className="MasterSetValue_Container">
                <BreadCrumb />
                <div className="MasterSetValue_Head">
                    Complete PSA Graded Master Set Value
                </div>
                <div className="MasterSetValue_Head_Text">
                    The complete set includes all holos, rares, commons,
                    uncommons, reverse foils and secret rare cards in
                    the set.
                    <br />
                    It does not include any graded sealed packs, promos,
                    pre-release cards. Popular error variations (inverted
                    back, miscut, off-centered, stained) are not included.
                    <br />
                    If no sales history for certain cards in the set, the
                    card will not also be included in the chart. If certain
                    cards does not have any recent sales info, we use the
                    last sold price.
                    <br />
                    <br />
                    <div style={{ fontStyle: "italic" }}>Note : Chart starts from $0 on 2019-01, most sets will
                        have a useable total value starting from 2019-05, do
                        not refer to any information in between, use with caution.
                    </div>
                </div>
                <div className="MasterSetValue_Set">
                    <div className="MasterSetValue_Set_Head" onClick={() => { setshowsets(true); }}>
                        {(setFilters) ? `${setFilters}` : "Fetching Set Data..."}
                        {showsets ? (
                            <>
                                <div className="MasterSetValue_Set_List_Container">
                                    <div className="MasterSetValue_Set_List">
                                        <CheckBoxSetMSV
                                            hideSets={() => {
                                                setshowsets(false);
                                            }}
                                            setFilters={setFilters}
                                            allSets={allsets}
                                            changeSets={(item) => {
                                                changeSets(item);
                                            }}
                                            refresh={refresh}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div>
                        <i
                            style={{ fontSize: "20px", color: "white", margin: "5px 0 0 7.5px ", cursor: "pointer" }}
                            onClick={() => { setshowsets(true); }}
                            class="fa fa-angle-down fa-1x">
                        </i>
                    </div>
                </div>
                <div className="MasterSetValue_Set_Chart">
                    <MasterSetValueChart setFilters={setFilters} />
                </div>
                <div className="MasterSetValue_Top_Cards_Section">
                    <MarketSetValueTopCards setFilters={setFilters} />
                </div>
            </div>
        </div >
    )
} 