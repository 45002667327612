import axios from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Navbar from "../../components/NavbarRest/NavbarRest";
import "./Verify.css";
axios.defaults.withCredentials = true

export default function Verify() {
    const history = useHistory();
    const search = useLocation().search;
    const confirmationCode = new URLSearchParams(search).get("code")
    const [MyText, setMyText] = React.useState("Verifying Email...")

    React.useEffect(() => {
        console.log(confirmationCode);
        if (!confirmationCode) {
            setMyText("Invalid Link")
        }
        else {
            axios.get("" + process.env.REACT_APP_BACKEND_URL + "api/user/verify/" + confirmationCode)
                .then((res) => {
                    console.log(res);
                    if (res.data.error) {
                        setMyText(res.data.message + "\n" + "Redirecting to login...")
                    }
                    else if (!res.data.error) {
                        setMyText(res.data.message + "\n" + "Redirecting to login...")
                    }
                    setTimeout(() => { history.push("/?login=true") }, 5000)
                })
                .catch((err) => {
                    setMyText("Unexpected Error Occured \n Please try again")
                    history.push("/")
                })
        }
    }, [])
    return (
        <div className="verify">
            <div className="verify__modal" >
                <div class="verify__screen" >
                    <div class="verify__screen__content__success">
                        <div className='successful__verify'>
                            <i className='successful__verify__icon fas fa-check-circle'></i>
                            <div className='successful__verify__text'>{MyText}</div>
                        </div>
                    </div>
                    <div class="verify__screen__background">
                        <span class="verify__screen__background__shape screen__background__shape4"></span>
                        <span class="verify__screen__background__shape screen__background__shape3"></span>
                        <span class="verify__screen__background__shape screen__background__shape2"></span>
                        <span class="verify__screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}